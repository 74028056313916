"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setDelegates = exports.resendDelegate = exports.removeDelegate = exports.getUserInfo = exports.getDelegates = exports.addDelegate = exports.SET_DELEGATES = exports.RESEND_DELEGATE_SUCCESS = exports.RESEND_DELEGATE_REQUEST = exports.RESEND_DELEGATE_FAILURE = exports.REMOVE_DELEGATE_SUCCESS = exports.REMOVE_DELEGATE_REQUEST = exports.REMOVE_DELEGATE_FAILURE = exports.GET_USER_SUCCESS = exports.GET_USER_REQUEST = exports.GET_USER_FAILURE = exports.GET_DELEGATES_SUCCESS = exports.GET_DELEGATES_REQUEST = exports.GET_DELEGATES_FAILURE = exports.ADD_DELEGATE_SUCCESS = exports.ADD_DELEGATE_REQUEST = exports.ADD_DELEGATE_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_DELEGATES_REQUEST = exports.GET_DELEGATES_REQUEST = 'GET_DELEGATES_REQUEST';
var GET_DELEGATES_SUCCESS = exports.GET_DELEGATES_SUCCESS = 'GET_DELEGATES_SUCCESS';
var GET_DELEGATES_FAILURE = exports.GET_DELEGATES_FAILURE = 'GET_DELEGATES_FAILURE';
var RESEND_DELEGATE_REQUEST = exports.RESEND_DELEGATE_REQUEST = 'RESEND_DELEGATE_REQUEST';
var RESEND_DELEGATE_SUCCESS = exports.RESEND_DELEGATE_SUCCESS = 'RESEND_DELEGATE_SUCCESS';
var RESEND_DELEGATE_FAILURE = exports.RESEND_DELEGATE_FAILURE = 'RESEND_DELEGATE_FAILURE';
var ADD_DELEGATE_REQUEST = exports.ADD_DELEGATE_REQUEST = 'ADD_DELEGATE_REQUEST';
var ADD_DELEGATE_SUCCESS = exports.ADD_DELEGATE_SUCCESS = 'ADD_DELEGATE_SUCCESS';
var ADD_DELEGATE_FAILURE = exports.ADD_DELEGATE_FAILURE = 'ADD_DELEGATE_FAILURE';
var REMOVE_DELEGATE_REQUEST = exports.REMOVE_DELEGATE_REQUEST = 'REMOVE_DELEGATE_REQUEST';
var REMOVE_DELEGATE_SUCCESS = exports.REMOVE_DELEGATE_SUCCESS = 'REMOVE_DELEGATE_SUCCESS';
var REMOVE_DELEGATE_FAILURE = exports.REMOVE_DELEGATE_FAILURE = 'REMOVE_DELEGATE_FAILURE';
var SET_DELEGATES = exports.SET_DELEGATES = 'SET_DELEGATES';
var GET_USER_REQUEST = exports.GET_USER_REQUEST = 'GET_USER_REQUEST';
var GET_USER_SUCCESS = exports.GET_USER_SUCCESS = 'GET_USER_SUCCESS';
var GET_USER_FAILURE = exports.GET_USER_FAILURE = 'GET_USER_FAILURE';
var getDelegates = exports.getDelegates = function getDelegates(surveyID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_DELEGATES_REQUEST, GET_DELEGATES_SUCCESS, GET_DELEGATES_FAILURE],
    authenticated: true,
    endpoint: "surveys/".concat(surveyID, "/delegates"),
    method: 'GET'
  });
};
var getUserInfo = exports.getUserInfo = function getUserInfo() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE],
    authenticated: true,
    endpoint: "Users/GetInfo",
    method: 'GET'
  });
};
var resendDelegate = exports.resendDelegate = function resendDelegate(surveyID, data, sendPlainText) {
  var resendData = {
    name: data.name,
    email: data.email
  };
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [RESEND_DELEGATE_REQUEST, RESEND_DELEGATE_SUCCESS, RESEND_DELEGATE_FAILURE],
    authenticated: true,
    endpoint: sendPlainText === true ? "surveys/".concat(surveyID, "/ResendEmailToDelegate?plainText=true") : "surveys/".concat(surveyID, "/ResendEmailToDelegate"),
    method: 'PUT',
    payload: resendData
  });
};
var addDelegate = exports.addDelegate = function addDelegate(surveyID, data, sendPlainText) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [ADD_DELEGATE_REQUEST, ADD_DELEGATE_SUCCESS, ADD_DELEGATE_FAILURE],
    authenticated: true,
    endpoint: sendPlainText === true ? "surveys/".concat(surveyID, "/addDelegate?plainText=true") : "surveys/".concat(surveyID, "/addDelegate"),
    method: 'PUT',
    payload: data
  });
};
var removeDelegate = exports.removeDelegate = function removeDelegate(surveyID, data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [REMOVE_DELEGATE_REQUEST, REMOVE_DELEGATE_SUCCESS, REMOVE_DELEGATE_FAILURE],
    authenticated: true,
    endpoint: "surveys/".concat(surveyID, "/removeDelegate"),
    method: 'PUT',
    payload: data
  });
};
var setDelegates = exports.setDelegates = function setDelegates(delegates) {
  return {
    type: SET_DELEGATES,
    response: delegates
  };
};