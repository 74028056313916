import jwt_decode from 'jwt-decode';
export const SET_AUTH = 'SET_AUTH';
export const CLEAR_AUTH = 'CLEAR_AUTH';

export const setAuth = auth => {
  return {
    type: SET_AUTH,
    response: auth !== null ? jwt_decode(auth) : ''
  };
};

export const clearAuth = () => {
  return {
    type: CLEAR_AUTH
  };
};
