"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTabs = exports.GET_TABS_SUCCESS = exports.GET_TABS_REQUEST = exports.GET_TABS_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_TABS_REQUEST = exports.GET_TABS_REQUEST = 'GET_TABS_REQUEST';
var GET_TABS_SUCCESS = exports.GET_TABS_SUCCESS = 'GET_TABS_SUCCESS';
var GET_TABS_FAILURE = exports.GET_TABS_FAILURE = 'GET_TABS_FAILURE';
var getTabs = exports.getTabs = function getTabs() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_TABS_REQUEST, GET_TABS_SUCCESS, GET_TABS_FAILURE],
    authenticated: true,
    endpoint: "tabs",
    method: 'GET'
  });
};