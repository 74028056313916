"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTechnologyQuestionsPages = void 0;
var _lodash = require("lodash");
var createTechnologyQuestionsPages = exports.createTechnologyQuestionsPages = function createTechnologyQuestionsPages(surveyType, technologies) {
  if (!surveyType || !technologies) {
    return [];
  }
  return surveyType.technologyQuestions.reduce(function (pagesList, q) {
    var tech = (0, _lodash.find)(technologies, {
      technologyID: q.technologyID
    });
    if ((0, _lodash.findIndex)(pagesList, {
      pageId: "tq_".concat(tech ? tech.technologyGroupID : '')
    }) === -1) {
      pagesList.push({
        pageId: tech ? 'tq_' + tech.technologyGroupID : '',
        haEntityTypeId: null,
        iconName: 'hospital',
        pageName: tech ? tech.technologyGroup : '',
        prompt: 'Page prompt TBD'
      });
    }
    return pagesList;
  }, []);
};