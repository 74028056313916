"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _technologies = require("../actions/technologies.actions");
var initialState = _immutable["default"].Map({
  technologies: [],
  isLoading: false,
  isApiError: false,
  errors: [],
  technology: {},
  technologiesList: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _technologies.GET_TECHNOLOGIES_REQUEST:
      return state.set('technologies', []).set('errors', []).set('isLoading', true);
    case _technologies.GET_TECHNOLOGIES_SUCCESS:
      var sortedList = [].concat(action.response);
      sortedList.sort(function (a, b) {
        var nameA = a.technologyName.toLowerCase(),
          nameB = b.technologyName.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      return state.set('technologies', sortedList).set('isLoading', false);
    case _technologies.GET_TECHNOLOGIES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _technologies.GET_TECHNOLOGIES_LIST_REQUEST:
      return state.set('technologiesList', []).set('errors', []).set('isLoading', true);
    case _technologies.GET_TECHNOLOGIES_LIST_SUCCESS:
      var sortedList = [].concat(action.response);
      sortedList.sort(function (a, b) {
        var nameA = a.label.toLowerCase(),
          nameB = b.label.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      console.log('WE HAVE GOTTEN A TECH LIST AND SORTED IT', sortedList);
      return state.set('technologiesList', sortedList).set('isLoading', false);
    case _technologies.GET_TECHNOLOGIES_LIST_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    default:
      return state;
  }
};