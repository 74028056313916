"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _immutable = _interopRequireDefault(require("immutable"));
var _lodash = require("lodash");
var _questions3 = require("../actions/questions.actions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var initialState = _immutable["default"].Map({
  questions: [],
  isLoading: false,
  isApiError: false,
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _questions3.GET_QUESTIONS_REQUEST:
      return state.set('questions', []).set('errors', []).set('isLoading', true);
    case _questions3.GET_QUESTIONS_SUCCESS:
      return state.set('questions', action.response).set('isLoading', false);
    case _questions3.GET_QUESTIONS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _questions3.CREATE_QUESTION_REQUEST:
      return state.set('isLoading', true);
    case _questions3.CREATE_QUESTION_SUCCESS:
      var newQuestion = action.response;
      var questions = [].concat(state.get('questions'));
      questions.push(newQuestion);
      return state.set('questions', questions).set('isLoading', false);
    case _questions3.CREATE_QUESTION_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _questions3.SAVE_QUESTION_REQUEST:
      return state.set('isLoading', true);
    case _questions3.SAVE_QUESTION_SUCCESS:
      {
        var _questions = [].concat(state.get('questions'));
        var indexToEdit = (0, _lodash.findIndex)(_questions, _objectSpread({}, action.payload));
        if (indexToEdit > -1) {
          _questions[indexToEdit].b = action.payload.message;
          _questions[indexToEdit].e = true;
          return state.set('questions', _questions).set('isLoading', false);
        }
        return state.set('isLoading', false);
      }
    case _questions3.SAVE_QUESTION_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _questions3.DELETE_QUESTION_SUCCESS:
      {
        var _questions2 = [].concat(state.get('questions'));
        var indexToDelete = (0, _lodash.findIndex)(_questions2, _objectSpread({}, action.payload));
        console.log(action);
        if (indexToDelete > -1) {
          _questions2.splice(indexToDelete, 1);
        }
        return state.set('questions', _questions2).set('isLoading', false);
      }
    case _questions3.DELETE_QUESTION_FAILURE:
      return state.set('errors', action.messages).set('isLoading', false);
    case _questions3.DELETE_QUESTION_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _questions3.UPLOAD_QUESTION_SUCCESS:
      {
        return state.set('questions', action.response).set('questionUploadComplete', true).set('isLoading', false);
      }
    case _questions3.UPLOAD_QUESTION_FAILURE:
      return state.set('errors', action.messages).set('questionUploadComplete', true).set('isLoading', false);
    case _questions3.UPLOAD_QUESTION_REQUEST:
      return state.set('errors', []).set('questionUploadComplete', false).set('isLoading', true);
    default:
      return state;
  }
};