"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRegionsList = exports.getRegions = exports.getCountryRegions = exports.getCountriesList = exports.getCountries = exports.GET_REGIONS_SUCCESS = exports.GET_REGIONS_REQUEST = exports.GET_REGIONS_LIST_SUCCESS = exports.GET_REGIONS_LIST_REQUEST = exports.GET_REGIONS_LIST_FAILURE = exports.GET_REGIONS_FAILURE = exports.GET_COUNTRY_REGIONS_SUCCESS = exports.GET_COUNTRY_REGIONS_REQUEST = exports.GET_COUNTRY_REGIONS_FAILURE = exports.GET_COUNTRIES_SUCCESS = exports.GET_COUNTRIES_REQUEST = exports.GET_COUNTRIES_LIST_SUCCESS = exports.GET_COUNTRIES_LIST_REQUEST = exports.GET_COUNTRIES_LIST_FAILURE = exports.GET_COUNTRIES_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_COUNTRY_REGIONS_REQUEST = exports.GET_COUNTRY_REGIONS_REQUEST = 'GET_COUNTRY_REGIONS_REQUEST';
var GET_COUNTRY_REGIONS_SUCCESS = exports.GET_COUNTRY_REGIONS_SUCCESS = 'GET_COUNTRY_REGIONS_SUCCESS';
var GET_COUNTRY_REGIONS_FAILURE = exports.GET_COUNTRY_REGIONS_FAILURE = 'GET_COUNTRY_REGIONS_FAILURE';
var GET_COUNTRIES_REQUEST = exports.GET_COUNTRIES_REQUEST = 'GET_COUNTRIES_REQUEST';
var GET_COUNTRIES_SUCCESS = exports.GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
var GET_COUNTRIES_FAILURE = exports.GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';
var GET_COUNTRIES_LIST_REQUEST = exports.GET_COUNTRIES_LIST_REQUEST = 'GET_COUNTRIES_LIST_REQUEST';
var GET_COUNTRIES_LIST_SUCCESS = exports.GET_COUNTRIES_LIST_SUCCESS = 'GET_COUNTRIES_LIST_SUCCESS';
var GET_COUNTRIES_LIST_FAILURE = exports.GET_COUNTRIES_LIST_FAILURE = 'GET_COUNTRIES_LIST_FAILURE';
var GET_REGIONS_LIST_REQUEST = exports.GET_REGIONS_LIST_REQUEST = 'GET_REGIONS_LIST_REQUEST';
var GET_REGIONS_LIST_SUCCESS = exports.GET_REGIONS_LIST_SUCCESS = 'GET_REGIONS_LIST_SUCCESS';
var GET_REGIONS_LIST_FAILURE = exports.GET_REGIONS_LIST_FAILURE = 'GET_REGIONS_LIST_FAILURE';
var GET_REGIONS_REQUEST = exports.GET_REGIONS_REQUEST = 'GET_REGIONS_REQUEST';
var GET_REGIONS_SUCCESS = exports.GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
var GET_REGIONS_FAILURE = exports.GET_REGIONS_FAILURE = 'GET_REGIONS_FAILURE';
var getCountries = exports.getCountries = function getCountries() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE],
    authenticated: true,
    endpoint: "countries",
    method: 'GET'
  });
};
var getRegions = exports.getRegions = function getRegions() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_REGIONS_REQUEST, GET_REGIONS_SUCCESS, GET_REGIONS_FAILURE],
    authenticated: true,
    endpoint: "regions",
    method: 'GET'
  });
};
var getCountryRegions = exports.getCountryRegions = function getCountryRegions(countryId) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_COUNTRY_REGIONS_REQUEST, GET_COUNTRY_REGIONS_SUCCESS, GET_COUNTRY_REGIONS_FAILURE],
    authenticated: true,
    endpoint: "countries/".concat(countryId, "/geographicalRegions"),
    method: 'GET'
  });
};
var getCountriesList = exports.getCountriesList = function getCountriesList() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_COUNTRIES_LIST_REQUEST, GET_COUNTRIES_LIST_SUCCESS, GET_COUNTRIES_LIST_FAILURE],
    authenticated: true,
    endpoint: 'countries/list',
    method: 'GET'
  });
};
var getRegionsList = exports.getRegionsList = function getRegionsList() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_REGIONS_LIST_REQUEST, GET_REGIONS_LIST_SUCCESS, GET_REGIONS_LIST_FAILURE],
    authenticated: true,
    endpoint: 'regions/list',
    method: 'GET'
  });
};