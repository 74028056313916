"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterQuestionsForSurvey = exports.filterQuestionsForEntity = exports.filterQuestion = exports.filterEntityQuestion = void 0;
var _lodash = require("lodash");
var _applySurveyTypeOverrides = require("./applySurveyTypeOverrides");
var _surveyUtils = require("./surveyUtils");
var filterQuestion = exports.filterQuestion = function filterQuestion(surveyType, question) {
  // If the question doesn't exist or isn't active, don't add it.
  if (!question || !question.active) {
    //console.log("couldn't find question");
    return 0;
  }

  // Make sure the survey start year is before the question end year
  if (question.endYear && surveyType.startYear > question.endYear) {
    console.log('wrong year for' + JSON.stringify(question));
    return 0;
    // Make sure the survey end year is after the question start year
  } else if (surveyType.endYear && surveyType.endYear < question.startYear) {
    console.log('wrong year 2 for' + JSON.stringify(question));
    return 0;
    // If the question and surveyType have applicable countries defined, make sure there is at least one overlapping country.
  } else if (question.countries && question.countries.length && surveyType.countries && surveyType.countries.length) {
    var hasMatch = surveyType.countries.filter(function (country) {
      return question.countries.includes(country);
    }).length;
    if (!hasMatch) {
      console.log('no country match ', question);
    }
    if (!hasMatch) return 0;
  }

  // If all the tests above pass, add the question to the list.
  return 1;
};
var filterQuestionsForSurvey = exports.filterQuestionsForSurvey = function filterQuestionsForSurvey(surveyType, allQuestions, language) {
  var isTechQuestions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var questions = surveyType.questions;
  if (isTechQuestions) {
    questions = surveyType.technologyQuestions;
  }
  if (!questions) {
    return [];
  }
  var _processQuestion = function processQuestion(all, curr) {
    /* If the question has already been processed, don't process it again. */
    if ((0, _lodash.findIndex)(all, {
      questionID: curr.questionID
    }) > -1) {
      return all;
    }
    var question = (0, _lodash.find)(allQuestions, {
      questionID: curr.questionID
    });
    if (filterQuestion(surveyType, question)) {
      all.push((0, _applySurveyTypeOverrides.updateQuestion)(curr, question, language));

      /* If this question has branching questions, process them */
      if (!(0, _lodash.isEmpty)(curr.branchingQuestions)) {
        var branchingQuestions = curr.branchingQuestions.reduce(_processQuestion, []);
        all.push.apply(all, branchingQuestions);
      }
    }
    return all;
  };
  return (0, _lodash.uniqBy)(questions.reduce(_processQuestion, []), function (q) {
    return q.questionID;
  });
};
var filterEntityQuestion = exports.filterEntityQuestion = function filterEntityQuestion(entity, surveyType, question, allQuestions) {
  // If the question doesn't exist or isn't active, don't add it.
  if (!question || !question.active) {
    //console.log("couldn't find question");
    return 0;
  }

  // Make sure the survey start year is before the question end year
  if (question.endYear && surveyType.startYear > question.endYear) {
    console.log('wrong year for' + JSON.stringify(question));
    return 0;
    // Make sure the survey end year is after the question start year
  } else if (surveyType.endYear && surveyType.endYear < question.startYear) {
    console.log('wrong year 2 for' + JSON.stringify(question));
    return 0;
    // If the question and surveyType have applicable countries defined, make sure there is at least one overlapping country.
  } else if (question.countries && question.countries.length && surveyType.countries && surveyType.countries.length) {
    var hasMatch = surveyType.countries.filter(function (country) {
      return question.countries.includes(country);
    }).length;
    if (!hasMatch) {
      console.log('no country match ', question);
    }
    if (!hasMatch) return 0;
  }
  var showQuestion = 1;
  if (question.parentQuestions && Object.keys(question.parentQuestions).length > 0) {
    var questionData = entity.data.questions;
    var addressQuestion = allQuestions.find(function (q) {
      return q.mappingGroupAttribute === '-5';
    });
    showQuestion = Object.keys(question.parentQuestions).every(function (k) {
      if (Number(k) === 805 && addressQuestion) {
        //if parent question is Country then look for Address question
        var result = (0, _surveyUtils.checkParentQuestionData)(addressQuestion.questionID.toString(), question.parentQuestions[k], questionData, addressQuestion);
        return result;
      } else {
        var parentQuestionInfo = allQuestions.find(function (q) {
          return q.questionID === Number(k);
        });
        var _result = (0, _surveyUtils.checkParentQuestionData)(k, question.parentQuestions[k], questionData, parentQuestionInfo);
        return _result;
      }
    });
  }

  // If all the tests above pass, add the question to the list.
  return showQuestion;
};
var filterQuestionsForEntity = exports.filterQuestionsForEntity = function filterQuestionsForEntity(entity, surveyType, allQuestions, language) {
  var isTechQuestions = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  var questions = surveyType.questions;
  if (isTechQuestions) {
    questions = surveyType.technologyQuestions;
  }
  if (!questions) {
    return [];
  }
  var _processQuestion2 = function processQuestion(all, curr) {
    /* If the question has already been processed, don't process it again. */
    if ((0, _lodash.findIndex)(all, {
      questionID: curr.questionID
    }) > -1) {
      return all;
    }
    var question = (0, _lodash.find)(allQuestions, {
      questionID: curr.questionID
    });
    if (filterEntityQuestion(entity, surveyType, question, allQuestions)) {
      all.push((0, _applySurveyTypeOverrides.updateQuestion)(curr, question, language));

      /* If this question has branching questions, process them */
      if (!(0, _lodash.isEmpty)(curr.branchingQuestions)) {
        var branchingQuestions = curr.branchingQuestions.reduce(_processQuestion2, []);
        all.push.apply(all, branchingQuestions);
      }
    }
    return all;
  };
  return (0, _lodash.uniqBy)(questions.reduce(_processQuestion2, []), function (q) {
    return q.questionID;
  });
};