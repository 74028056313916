"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.saveSurveyFile = exports.getSurveyFileDetail = exports.getSurveyFile = exports.SAVE_SURVEY_FILE_SUCCESS = exports.SAVE_SURVEY_FILE_REQUEST = exports.SAVE_SURVEY_FILE_FAILURE = exports.GET_SURVEY_FILE_SUCCESS = exports.GET_SURVEY_FILE_REQUEST = exports.GET_SURVEY_FILE_FAILURE = exports.GET_SURVEY_FILE_DETAIL_SUCCESS = exports.GET_SURVEY_FILE_DETAIL_REQUEST = exports.GET_SURVEY_FILE_DETAIL_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var SAVE_SURVEY_FILE_FAILURE = exports.SAVE_SURVEY_FILE_FAILURE = 'SAVE_SURVEY_FILE_FAILURE';
var SAVE_SURVEY_FILE_REQUEST = exports.SAVE_SURVEY_FILE_REQUEST = 'SAVE_SURVEY_FILE_REQUEST';
var SAVE_SURVEY_FILE_SUCCESS = exports.SAVE_SURVEY_FILE_SUCCESS = 'SAVE_SURVEY_FILE_SUCCESS';
var GET_SURVEY_FILE_FAILURE = exports.GET_SURVEY_FILE_FAILURE = 'GET_SURVEY_FILE_FAILURE';
var GET_SURVEY_FILE_REQUEST = exports.GET_SURVEY_FILE_REQUEST = 'GET_SURVEY_FILE_REQUEST';
var GET_SURVEY_FILE_SUCCESS = exports.GET_SURVEY_FILE_SUCCESS = 'GET_SURVEY_FILE_SUCCESS';
var GET_SURVEY_FILE_DETAIL_FAILURE = exports.GET_SURVEY_FILE_DETAIL_FAILURE = 'GET_SURVEY_FILE_DETAIL_FAILURE';
var GET_SURVEY_FILE_DETAIL_REQUEST = exports.GET_SURVEY_FILE_DETAIL_REQUEST = 'GET_SURVEY_FILE_DETAIL_REQUEST';
var GET_SURVEY_FILE_DETAIL_SUCCESS = exports.GET_SURVEY_FILE_DETAIL_SUCCESS = 'GET_SURVEY_FILE_DETAIL_SUCCESS';
var saveSurveyFile = exports.saveSurveyFile = function saveSurveyFile(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [SAVE_SURVEY_FILE_REQUEST, SAVE_SURVEY_FILE_SUCCESS, SAVE_SURVEY_FILE_FAILURE],
    authenticated: true,
    endpoint: "documents/UploadFileToS3",
    method: 'POST',
    payload: data
  });
};
var getSurveyFile = exports.getSurveyFile = function getSurveyFile(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_SURVEY_FILE_REQUEST, GET_SURVEY_FILE_SUCCESS, GET_SURVEY_FILE_FAILURE],
    authenticated: true,
    endpoint: "documents/GetDocumentByFileName",
    method: 'POST',
    payload: data,
    isBlob: true
  });
};
var getSurveyFileDetail = exports.getSurveyFileDetail = function getSurveyFileDetail(filename) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_SURVEY_FILE_DETAIL_REQUEST, GET_SURVEY_FILE_DETAIL_SUCCESS, GET_SURVEY_FILE_DETAIL_FAILURE],
    authenticated: true,
    endpoint: "documents/GetDocumentDetailByFileName/".concat(filename),
    method: 'GET'
  });
};