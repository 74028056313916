"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _countries = require("../actions/countries.actions");
var initialState = _immutable["default"].Map({
  countries: [],
  countriesList: [],
  countryRegions: [],
  regionsList: [],
  regions: [],
  isLoading: false,
  isApiError: false,
  isCountryRegionsLoading: false,
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _countries.GET_COUNTRIES_REQUEST:
      return state.set('countries', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _countries.GET_COUNTRIES_SUCCESS:
      return state.set('countries', action.response).set('isLoading', false);
    case _countries.GET_COUNTRIES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _countries.GET_COUNTRY_REGIONS_REQUEST:
      return state.set('countryRegions', []).set('errors', []).set('isApiError', false).set('isCountryRegionsLoading', true);
    case _countries.GET_COUNTRY_REGIONS_SUCCESS:
      return state.set('countryRegions', action.response).set('isCountryRegionsLoading', false);
    case _countries.GET_COUNTRY_REGIONS_FAILURE:
      return state.set('isCountryRegionsLoading', false).set('error', action.messages).set('isApiError', true);
    case _countries.GET_REGIONS_REQUEST:
      return state.set('regions', []).set('errors', []).set('isApiError', false).set('isRegionsLoading', true);
    case _countries.GET_REGIONS_SUCCESS:
      return state.set('regions', action.response).set('isRegionsLoading', false);
    case _countries.GET_REGIONS_FAILURE:
      return state.set('isRegionsLoading', false).set('error', action.messages).set('isApiError', true);
    case _countries.GET_COUNTRIES_LIST_REQUEST:
      return state.set('countriesList', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _countries.GET_COUNTRIES_LIST_SUCCESS:
      return state.set('countriesList', action.response).set('isLoading', false);
    case _countries.GET_COUNTRIES_LIST_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _countries.GET_REGIONS_LIST_REQUEST:
      return state.set('regionsList', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _countries.GET_REGIONS_LIST_SUCCESS:
      return state.set('regionsList', action.response).set('isLoading', false);
    case _countries.GET_REGIONS_LIST_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    default:
      return state;
  }
};