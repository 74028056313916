"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _entities = require("../actions/entities.actions");
var initialState = _immutable["default"].Map({
  entity: {},
  entityTypes: [],
  entityHospitals: [],
  entityAmbulatories: [],
  entitySubAcutes: [],
  entityHomeHealths: [],
  entityFreeStandingDataCenters: [],
  entityInHospitalDataCenters: [],
  entityUntetheredAmbulatories: [],
  isLoading: false,
  isApiError: false,
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _entities.GET_ENTITY_TYPES_REQUEST:
      return state.set('entityTypes', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_TYPES_SUCCESS:
      return state.set('entityTypes', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_TYPES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_HOSPITALS_REQUEST:
      console.log('GETTING HOSPITAL!');
      return state.set('entityHospitals', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_HOSPITALS_SUCCESS:
      return state.set('entityHospitals', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_HOSPITALS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_AMBULATORIES_REQUEST:
      return state.set('entityAmbulatories', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_AMBULATORIES_SUCCESS:
      return state.set('ambulatories', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_AMBULATORIES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_SUBACUTES_REQUEST:
      return state.set('entitySubAcutes', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_SUBACUTES_SUCCESS:
      return state.set('entitySubAcutes', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_SUBACUTES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_HOME_HEALTHS_REQUEST:
      return state.set('entityHomeHealths', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_HOME_HEALTHS_SUCCESS:
      return state.set('entityHomeHealths', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_HOME_HEALTHS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_FREE_STANDING_DATA_CENTERS_REQUEST:
      return state.set('entityFreeStandingDataCenters', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_FREE_STANDING_DATA_CENTERS_SUCCESS:
      return state.set('entityFreeStandingDataCenters', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_FREE_STANDING_DATA_CENTERS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_REQUEST:
      return state.set('entityInHospitalDataCenters', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_SUCCESS:
      return state.set('entityInHospitalDataCenters', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _entities.GET_ENTITY_UNTETHERED_AMBULATORIES_REQUEST:
      return state.set('entityUntetheredAmbulatories', []).set('errors', []).set('isApiError', false).set('isLoading', true);
    case _entities.GET_ENTITY_UNTETHERED_AMBULATORIES_SUCCESS:
      return state.set('entityUntetheredAmbulatories', action.response).set('isLoading', false);
    case _entities.GET_ENTITY_UNTETHERED_AMBULATORIES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    default:
      return state;
  }
};