import Immutable from 'immutable';
import { SET_PAGE_TITLE, SET_RESPONSIVE_BREAKPOINT, SHOW_TOAST_MESSAGE, HIDE_TOAST_MESSAGE, SET_TOAST_POSITION } from './layout.actions';

const initialState = Immutable.Map({
  pageTitle: 'Home',
  responsiveBreakpoint: '',

  isShowToast: false,
  toastMessage: '',
  isToastFailure: false,
  toastPosition: '0'
});

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_PAGE_TITLE:
      return state.set('pageTitle', action.payload);

    case SET_RESPONSIVE_BREAKPOINT:
      return state.set('responsiveBreakpoint', action.payload);

    case SHOW_TOAST_MESSAGE:
      return state
        .set('isShowToast', true)
        .set('toastMessage', action.message)
        .set('isToastFailure', action.isFailure);

    case HIDE_TOAST_MESSAGE:
      return state.set('isShowToast', false);

    case SET_TOAST_POSITION:
      return state.set('toastPosition', action.position);

    default:
      return state;
  }
}
