"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _organizations = require("../actions/organizations.actions");
var initialState = _immutable["default"].Map({
  questions: [],
  organizationTypes: [],
  accountList: [],
  isLoading: false,
  isApiError: false,
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _organizations.GET_ORGANIZATION_TYPES_REQUEST:
      return state.set('organizationTypes', []).set('errors', []).set('isLoading', true);
    case _organizations.GET_ORGANIZATION_TYPES_SUCCESS:
      return state.set('organizationTypes', action.response).set('isLoading', false);
    case _organizations.GET_ORGANIZATION_TYPES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _organizations.GET_ACCOUNTS_BY_TYPE_REQUEST:
      return state.set('accountList', []).set('errors', []).set('isLoading', true);
    case _organizations.GET_ACCOUNTS_BY_TYPE_SUCCESS:
      return state.set('accountList', action.response).set('isLoading', false);
    case _organizations.GET_ACCOUNTS_BY_TYPE_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _organizations.VALIDATE_ADDRESS_REQUEST:
      return state.set('isApiError', false);
    case _organizations.VALIDATE_ADDRESS_SUCCESS:
      return state;
    case _organizations.VALIDATE_ADDRESS_FAILURE:
      return state.set('error', action.messages).set('isApiError', true);
    default:
      return state;
  }
};