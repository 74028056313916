"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateAddress = exports.getOrganizationTypes = exports.getAccountsByType = exports.VALIDATE_ADDRESS_SUCCESS = exports.VALIDATE_ADDRESS_REQUEST = exports.VALIDATE_ADDRESS_FAILURE = exports.GET_ORGANIZATION_TYPES_SUCCESS = exports.GET_ORGANIZATION_TYPES_REQUEST = exports.GET_ORGANIZATION_TYPES_FAILURE = exports.GET_ACCOUNTS_BY_TYPE_SUCCESS = exports.GET_ACCOUNTS_BY_TYPE_REQUEST = exports.GET_ACCOUNTS_BY_TYPE_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_ORGANIZATION_TYPES_REQUEST = exports.GET_ORGANIZATION_TYPES_REQUEST = 'GET_ORGANIZATION_TYPES_REQUEST';
var GET_ORGANIZATION_TYPES_SUCCESS = exports.GET_ORGANIZATION_TYPES_SUCCESS = 'GET_ORGANIZATION_TYPES_SUCCESS';
var GET_ORGANIZATION_TYPES_FAILURE = exports.GET_ORGANIZATION_TYPES_FAILURE = 'GET_ORGANIZATION_TYPES_FAILURE';
var VALIDATE_ADDRESS_REQUEST = exports.VALIDATE_ADDRESS_REQUEST = 'VALIDATE_ADDRESS_REQUEST';
var VALIDATE_ADDRESS_SUCCESS = exports.VALIDATE_ADDRESS_SUCCESS = 'VALIDATE_ADDRESS_SUCCESS';
var VALIDATE_ADDRESS_FAILURE = exports.VALIDATE_ADDRESS_FAILURE = 'VALIDATE_ADDRESS_FAILURE';
var GET_ACCOUNTS_BY_TYPE_REQUEST = exports.GET_ACCOUNTS_BY_TYPE_REQUEST = 'GET_ACCOUNTS_BY_TYPE_REQUEST';
var GET_ACCOUNTS_BY_TYPE_SUCCESS = exports.GET_ACCOUNTS_BY_TYPE_SUCCESS = 'GET_ACCOUNTS_BY_TYPE_SUCCESS';
var GET_ACCOUNTS_BY_TYPE_FAILURE = exports.GET_ACCOUNTS_BY_TYPE_FAILURE = 'GET_ACCOUNTS_BY_TYPE_FAILURE';
var getOrganizationTypes = exports.getOrganizationTypes = function getOrganizationTypes() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ORGANIZATION_TYPES_REQUEST, GET_ORGANIZATION_TYPES_SUCCESS, GET_ORGANIZATION_TYPES_FAILURE],
    authenticated: true,
    endpoint: "organizationTypes",
    method: 'GET'
  });
};
var getAccountsByType = exports.getAccountsByType = function getAccountsByType(entityTypeIDs) {
  var ids = entityTypeIDs.reduce(function (v1, v2) {
    return v1 + "entityTypeIDs=".concat(v2, "&");
  }, '');
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ACCOUNTS_BY_TYPE_REQUEST, GET_ACCOUNTS_BY_TYPE_SUCCESS, GET_ACCOUNTS_BY_TYPE_FAILURE],
    authenticated: true,
    endpoint: "accounts/byType?".concat(ids),
    method: 'GET'
  });
};
var validateAddress = exports.validateAddress = function validateAddress(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [VALIDATE_ADDRESS_REQUEST, VALIDATE_ADDRESS_SUCCESS, VALIDATE_ADDRESS_FAILURE],
    authenticated: true,
    endpoint: "accounts/validateAddress",
    method: 'POST',
    payload: data
  });
};