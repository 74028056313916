"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validationRulesList = exports.customValidationRules = void 0;
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _libphonenumberJs = require("libphonenumber-js");
var _lodash = require("lodash");
var validationRulesList = exports.validationRulesList = [{
  name: 'Email Address',
  validatorFunctionName: 'isEmail',
  errorMessage: 'Please enter a valid email address'
}, {
  name: 'Phone Number',
  validatorFunctionName: 'isPhoneNumber',
  errorMessage: 'Please enter a valid phone number'
}, {
  name: 'Max Answer Length',
  validatorFunctionName: 'maxStringLength',
  errorMessage: 'Maximum field length exceeded'
}, {
  name: 'Select',
  validatorFunctionName: 'isSelectionValid',
  errorMessage: 'This field is required'
}, {
  name: 'Number',
  validatorFunctionName: 'isFloat',
  errorMessage: 'Must be a numeric value'
}, {
  name: 'Percent',
  validatorFunctionName: 'isPercent',
  errorMessage: 'Must be a numeric value'
}, {
  name: 'Currency - Dollar',
  validatorFunctionName: 'isCurrencyDollar',
  errorMessage: 'Must be a numeric value'
}, {
  name: 'customRequired',
  validatorFunctionName: 'customRequired',
  errorMessage: 'This field is required'
}];
var customRequired = function customRequired(value, params) {
  return value && value.length > 0;
};
var phoneNumberValidation = function phoneNumberValidation(number, params) {
  try {
    // Remove any beginning zeros because the library doesn't know how to parse them.
    number = number.toString().replace(/^0+/, '');
    // See if there is a valid phone number here, starting with US numbers.
    var num = (0, _libphonenumberJs.findNumbers)(number, 'US', {
      v2: true
    });
    // If there isn't a matching number, try adding a plus at the beginning. Sometimes it helps the library to find international numbers.
    if (!num.length) {
      number = "+".concat(number);
    }
    // Try again. If we still fail then the number probably isn't valid.
    num = (0, _libphonenumberJs.findNumbers)(number, 'US', {
      v2: true
    });
    return num.length;
  } catch (e) {
    console.log(e);
    return false;
  }
};
var contractSignedDateValidation = function contractSignedDateValidation(value, params) {
  var isValid = true;
  {
    /*if (params.questions && params.answers) {
    const question = params.questions.find(q => q.mappingGroupAttribute == '97');
    if (question) {
      const answer = params.answers.find(a => a.answerID == question.answerID);
      if (answer) {
        var val = answer.answers.find(a => a.value == value);
        console.log('val:', value, val);
        isValid = val.label >= new Date().getFullYear();
      }
    }*/
  }
  var val = JSON.parse(value);
  if (val.length > 0) {
    console.log('value: ', val[0]);
    isValid = parseInt(val[0].label, 10) >= new Date().getFullYear();
  }
  return isValid;
};
var selectValidation = function selectValidation(value, params) {
  if (value === '') {
    return false;
  }
  var obj = JSON.parse(value);
  if ((0, _lodash.isArray)(obj)) {
    return obj.length > 0 && obj.every(function (selection) {
      return (0, _typeof2["default"])(selection) === 'object' && 'label' in selection && 'value' in selection;
    });
  } else if ((0, _typeof2["default"])(obj) === 'object') {
    return 'label' in obj && 'value' in obj;
  }
  return false;
};
var isAddressValid = function isAddressValid(value) {
  return false;
};
var customValidationRules = exports.customValidationRules = {
  isPhoneNumber: phoneNumberValidation,
  isSelectionValid: selectValidation,
  contractSignedDateValidation: contractSignedDateValidation,
  customRequired: customRequired
};