"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createSurveyStateForEntity = exports.createSurveyState = void 0;
var _filterQuestions = require("./filterQuestions");
var _createTechnologyQuestionsPages = require("../utilities/createTechnologyQuestionsPages");
var _createTechnologyQuestionsTabs = require("../utilities/createTechnologyQuestionsTabs");
var _lodash = require("lodash");
var _pages = require("../actions/pages.actions");
var _getResource = require("@survey/common/dist/utilities/getResource");
var createSurveyState = exports.createSurveyState = function createSurveyState(survey, surveyType, entityTypeId, pages, tabs, technologies, resources) {
  var state = {
    questions: [],
    pages: [],
    tabs: [],
    surveyType: {},
    technologyQuestions: []
  };

  //NOTE! pageSortOrder on SurveyTypes needs to be set in Mongo, this was the issue with AMAM pages,
  //if pages are out of order in a Survey, set them there!

  // Cut down the list of questions to ones that apply to this survey.
  if (survey && surveyType) {
    state.surveyType = surveyType;
    state.questions = (0, _filterQuestions.filterQuestionsForSurvey)(surveyType, survey.questions, survey.language);
    state.technologyQuestions = (0, _filterQuestions.filterQuestionsForSurvey)(surveyType, survey.technologyQuestions, survey.language, true);

    //updatedQuestionsList is [] when passed to getVisiblePages for DIAM HS
    //in this: q.entityTypes.forEach, 6 (HS) never gets pushed to haEntityTypes (only 1 and 3 do)
    //each question in state.questions must have an entityTypeId. it seems that for this survey the entity type in all questions is 1
    //these are set in surveybuilder, example: : to add some questions that have HAEntityType 6 or 8 (HS ids) to a survey,
    //go to the DIAM survey in surveybuilder, then questions, then Org and Contact Detail Page and added all.

    if (pages && tabs && !isNaN(entityTypeId)) {
      if (state.questions) {
        var updatedQuestionsList = state.questions.reduce(function (all, q) {
          var haEntityTypes = [];
          if (survey.entityTypes) {
            q.entityTypes.forEach(function (qe) {
              var entityType = survey.entityTypes.find(function (e) {
                return e.entityTypeId === Number(qe);
              });
              if (entityType) {
                haEntityTypes.push.apply(haEntityTypes, entityType.haEntityTypes);
              }
            });
          }
          if (haEntityTypes.length === 0 || haEntityTypes.includes(entityTypeId)) {
            all.push(q);
          }
          return all;
        }, []);
        state.questions = [].concat(updatedQuestionsList);
        var visiblePages = (0, _lodash.cloneDeep)((0, _pages.getVisiblePages)(pages, tabs, survey.questions, updatedQuestionsList));
        if (survey.language && resources) {
          visiblePages = visiblePages.forEach(function (p) {
            p.pageName = (0, _getResource.getResource)(resources, survey.language, 'Page', p.pageName);
          });
        }
        state.pages = visiblePages;
        //state.pages = sortBy(visiblePages, ['sortOrder','pageName']);
      }
    }
    var visibleTabs = (0, _lodash.cloneDeep)(tabs);
    visibleTabs.forEach(function (t) {
      t.tabName = (0, _getResource.getResource)(resources, survey.language, 'Tab', t.tabName);
      var tabSortOrder = surveyType.tabSortOrder.find(function (ts) {
        return ts.pageID === t.pageId.toString() && ts.tabID === t.tabId.toString();
      });
      if (tabSortOrder) {
        t.sortOrder = tabSortOrder.sortOrder;
      } else {
        //just arbitrary number
        t.sortOrder = 9999;
      }
    });
    visibleTabs = (0, _lodash.cloneDeep)((0, _lodash.sortBy)(visibleTabs, ['sortOrder', 'tabName']));
    state.tabs = visibleTabs;
    if (state.technologyQuestions) {
      var _state$pages, _state$tabs;
      var techPages = (0, _createTechnologyQuestionsPages.createTechnologyQuestionsPages)(surveyType, technologies);
      //techPages = sortBy(techPages, ['pageName']);
      (_state$pages = state.pages).push.apply(_state$pages, techPages);
      state.pages.forEach(function (vp) {
        var pageSortOrder = surveyType.pageSortOrder.find(function (ps) {
          return ps.pageID === vp.pageId.toString();
        });
        if (pageSortOrder) {
          vp.sortOrder = pageSortOrder.sortOrder;
        } else {
          //just arbitrary number
          vp.sortOrder = 9999;
        }
      });
      state.pages = (0, _lodash.sortBy)(state.pages, ['sortOrder', 'pageName']);
      var techTabs = (0, _createTechnologyQuestionsTabs.createTechnologyQuestionsTabs)(surveyType, technologies);
      //Custom sorting algorithm to push Tech Rollup tabs to be the first tab on their respective pages
      //techTabs.sort(function(a, b){
      //  if (b.tabName === 'IS Security' || b.tabName === 'Ambulatory PACS' || b.tabName === 'Cardiology Image Distribution' || b.tabName === 'Radiology Image Distribution') {
      //    return 1;
      //  }
      //  if (a.tabName === 'IS Security' || a.tabName === 'Ambulatory PACS' || a.tabName === 'Cardiology Image Distribution' || a.tabName === 'Radiology Image Distribution') {
      //    return -1;
      //  }
      //  return a.tabName > b.tabName ? 1 : -1;
      //});
      techTabs.forEach(function (t) {
        var tabSortOrder = surveyType.tabSortOrder.find(function (ts) {
          return ts.pageID === t.pageId.toString() && ts.tabID === t.tabId.toString();
        });
        if (tabSortOrder) {
          t.sortOrder = tabSortOrder.sortOrder;
        } else {
          //just arbitrary number
          t.sortOrder = 9999;
        }
      });
      techTabs = (0, _lodash.cloneDeep)((0, _lodash.sortBy)(techTabs, ['sortOrder', 'tabName']));
      techTabs.forEach(function (t) {
        t.tabName = (0, _getResource.getResource)(resources, survey.language, 'Technology', t.tabName);
      });
      (_state$tabs = state.tabs).push.apply(_state$tabs, techTabs);
    }
  }
  return state;
};
var createSurveyStateForEntity = exports.createSurveyStateForEntity = function createSurveyStateForEntity(entity, survey, surveyType, entityTypeId, pages, tabs, technologies, resources) {
  var state = {
    questions: [],
    pages: [],
    tabs: [],
    surveyType: {},
    technologyQuestions: []
  };

  //NOTE! pageSortOrder on SurveyTypes needs to be set in Mongo, this was the issue with AMAM pages,
  //if pages are out of order in a Survey, set them there!

  // Cut down the list of questions to ones that apply to this survey.
  if (survey && surveyType) {
    state.surveyType = surveyType;
    var hsOnlySurvey = survey.entities.length === 1 && [6, 8].includes(survey.entities[0].haEntityTypeID);
    state.questions = (0, _filterQuestions.filterQuestionsForEntity)(entity, surveyType, survey.questions, survey.language);
    state.technologyQuestions = (0, _filterQuestions.filterQuestionsForEntity)(entity, surveyType, survey.technologyQuestions, survey.language, true);
    if (pages && tabs && !isNaN(entityTypeId)) {
      if (state.questions) {
        var updatedQuestionsList = state.questions.reduce(function (all, q) {
          var haEntityTypes = [];
          q.entityTypes.forEach(function (qe) {
            var entityType = survey.entityTypes.find(function (e) {
              return e.entityTypeId === Number(qe);
            });
            if (entityType) {
              haEntityTypes.push.apply(haEntityTypes, entityType.haEntityTypes);
            }
          });
          //if the survey contains health system only, then show all questions that has Facility entity type (ID:1)
          if (hsOnlySurvey && q.entityTypes.includes(1) || haEntityTypes.length === 0 || haEntityTypes.includes(entityTypeId)) {
            all.push(q);
          }
          return all;
        }, []);
        state.questions = [].concat(updatedQuestionsList);
        var visiblePages = (0, _lodash.cloneDeep)((0, _pages.getVisiblePages)(pages, tabs, survey.questions, updatedQuestionsList));
        if (survey.language && resources) {
          visiblePages.forEach(function (p) {
            p.pageName = (0, _getResource.getResource)(resources, survey.language, 'Page', p.pageName);
          });
        }
        state.pages = visiblePages;
      }
    }
    var visibleTabs = (0, _lodash.cloneDeep)(tabs);
    visibleTabs.forEach(function (t) {
      t.tabName = (0, _getResource.getResource)(resources, survey.language, 'Tab', t.tabName);
      var tabSortOrder = surveyType.tabSortOrder.find(function (ts) {
        return ts.pageID === t.pageId.toString() && ts.tabID === t.tabId.toString();
      });
      if (tabSortOrder) {
        t.sortOrder = tabSortOrder.sortOrder;
      } else {
        //just arbitrary number
        t.sortOrder = 9999;
      }
    });
    visibleTabs = (0, _lodash.cloneDeep)((0, _lodash.sortBy)(visibleTabs, ['sortOrder', 'tabName']));
    state.tabs = visibleTabs;
    if (state.technologyQuestions) {
      var _state$pages2, _state$tabs2;
      var _updatedQuestionsList = state.technologyQuestions.reduce(function (all, q) {
        var haEntityTypes = [];
        q.entityTypes.forEach(function (qe) {
          var entityType = survey.entityTypes.find(function (e) {
            return e.entityTypeId === Number(qe);
          });
          if (entityType) {
            haEntityTypes.push.apply(haEntityTypes, entityType.haEntityTypes);
          }
        });
        if (hsOnlySurvey && q.entityTypes.includes(1) || haEntityTypes.length === 0 || haEntityTypes.includes(entityTypeId)) {
          all.push(q);
        }
        return all;
      }, []);
      state.technologyQuestions = [].concat(_updatedQuestionsList);
      var techPages = Object.keys(state.technologyQuestions).length > 0 ? (0, _lodash.cloneDeep)((0, _createTechnologyQuestionsPages.createTechnologyQuestionsPages)(surveyType, technologies)) : [];
      if (survey.language && resources) {
        techPages.forEach(function (p) {
          p.pageName = (0, _getResource.getResource)(resources, survey.language, 'Page', p.pageName);
        });
      }

      //techPages = sortBy(techPages, ['pageName']);
      (_state$pages2 = state.pages).push.apply(_state$pages2, techPages);
      state.pages.forEach(function (vp) {
        var pageSortOrder = surveyType.pageSortOrder.find(function (ps) {
          return ps.pageID === vp.pageId.toString();
        });
        if (pageSortOrder) {
          vp.sortOrder = pageSortOrder.sortOrder;
        } else {
          //just arbitrary number
          vp.sortOrder = 9999;
        }
      });
      state.pages = (0, _lodash.sortBy)(state.pages, ['sortOrder', 'pageName']);
      var techTabs = Object.keys(state.technologyQuestions).length > 0 ? (0, _createTechnologyQuestionsTabs.createTechnologyQuestionsTabs)(surveyType, technologies) : [];
      techTabs.forEach(function (t) {
        var tabSortOrder = surveyType.tabSortOrder.find(function (ts) {
          return ts.pageID === t.pageId.toString() && ts.tabID === t.tabId.toString();
        });
        if (tabSortOrder) {
          t.sortOrder = tabSortOrder.sortOrder;
        } else {
          //just arbitrary number
          t.sortOrder = 9999;
        }
      });
      techTabs = (0, _lodash.cloneDeep)((0, _lodash.sortBy)(techTabs, ['sortOrder', 'tabName']));
      techTabs.forEach(function (t) {
        t.tabName = (0, _getResource.getResource)(resources, survey.language, 'Technology', t.tabName);
      });
      (_state$tabs2 = state.tabs).push.apply(_state$tabs2, techTabs);
    }
  }
  return state;
};