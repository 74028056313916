"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.externalURL = exports["default"] = exports.baseURL = exports.CALL_API = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
// Found this at https://github.com/auth0-blog/redux-auth

var EXTERNAL_URL = process.env.REACT_APP_EXTERNAL_URL ? process.env.REACT_APP_EXTERNAL_URL : 'https://survey.dev.himssanalytics.org/';
// let EXTERNAL_URL = 'https://survey.staging.himssanalytics.org/';
// let EXTERNAL_URL = 'https://survey.himssanalytics.org/';

var externalURL = exports.externalURL = EXTERNAL_URL;
var BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : 'https://surveybuilder.dev.himssanalytics.org/api/';
// let BASE_URL = 'https://surveybuilder.staging.himssanalytics.org/api/';
// let BASE_URL = 'https://surveybuilder.himssanalytics.org/api/';

if (process.env.REACT_APP_LOCAL_API) {
  BASE_URL = 'http://localhost:49536/api/';
}
var baseURL = exports.baseURL = BASE_URL;

/*//There should be a better way
let $_GET = {};
if(document.location.toString().indexOf('?') !== -1) {
    var query = document.location
        .toString()
        // get the query string
        .replace(/^.*?\?/, '')
        // and remove any existing hash string (thanks, @vrijdenker)
        .replace(/#.*$/, '')
        .split('&');

    for(var i=0, l=query.length; i<l; i++) {
        var aux = decodeURIComponent(query[i]).split('=');
        $_GET[aux[0]] = aux[1];
    }
}*/

function callApi(token, endpoint, authenticated) {
  var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'GET';
  var payload = arguments.length > 4 ? arguments[4] : undefined;
  var email = arguments.length > 5 ? arguments[5] : undefined;
  var isBlob = arguments.length > 6 ? arguments[6] : undefined;
  var config = {
    headers: {}
  };
  if (process.env.REACT_APP_AUTH0_USE_AUTH === 'true') {
    if (authenticated) {
      if (token) {
        config = {
          headers: {
            Authorization: "Bearer ".concat(token)
          }
        };
      } else {
        throw new Error('No token saved!');
      }
    }
  } else {
    config = {
      headers: {
        //Authorization: 'Basic dGVzdFVzZXI6dGVzdFVzZXI='
        Authorization: 'Basic ZXh0ZXJuYWxTdXJ2ZXlVc2VyOmV4dGVybmFsU3VydmV5VXNlciE=',
        'HA-AccessPin': sessionStorage.getItem('HAAccessPin')
      }
    };
  }
  config.method = method;
  if (typeof payload !== 'undefined') {
    console.log('payload type', Object.prototype.toString.call(payload));
    //Is this form data?
    if (Object.prototype.toString.call(payload) === '[object FormData]') {
      config.body = payload;
    } else {
      config.body = JSON.stringify(payload);
      config.headers['Content-Type'] = 'application/json';
    }
  }
  var url = '';
  if (endpoint.startsWith('http')) {
    url = endpoint;
  } else {
    url = BASE_URL + endpoint;
  }
  return fetch(url, config).then(function (response) {
    if (config.method === 'DELETE') {
      return {
        response: response
      };
    } else if (isBlob === true) {
      var filename = response.headers.get('content-disposition').split(';')[1].split('=')[1];
      response.headers.forEach(function (h) {
        return console.log(h);
      });
      console.log('filename', filename, response.headers.get('content-disposition'));
      return response.blob().then(function (blob) {
        return {
          blob: blob,
          response: response,
          filename: filename
        };
      });
    } else {
      return response.json().then(function (json) {
        return {
          json: json,
          response: response
        };
      });
    }
  }).then(function (_ref) {
    var json = _ref.json,
      response = _ref.response,
      blob = _ref.blob,
      filename = _ref.filename;
    if (!response.ok) {
      json.status = response.status;
      return Promise.reject(json);
    }
    var metadata = {};
    if (response.headers.get('X-Total-Count')) {
      metadata.totalCount = Number(response.headers.get('X-Total-Count'));
    }
    return {
      json: json,
      metadata: metadata,
      blob: blob,
      filename: filename
    };
  });
}
var CALL_API = exports.CALL_API = Symbol('Call API');
var _default = exports["default"] = function _default(_ref2) {
  var dispatch = _ref2.dispatch,
    getState = _ref2.getState;
  return function (next) {
    return function (action) {
      var authToken = localStorage.getItem('access_token');
      var callAPI = action[CALL_API];

      // So the middleware doesn't get applied to every single action
      if (typeof callAPI === 'undefined') {
        return next(action);
      }
      var endpoint = callAPI.endpoint,
        types = callAPI.types,
        authenticated = callAPI.authenticated,
        method = callAPI.method,
        payload = callAPI.payload,
        options = callAPI.options,
        email = callAPI.email,
        isBlob = callAPI.isBlob;
      var _types = (0, _slicedToArray2["default"])(types, 3),
        requestType = _types[0],
        successType = _types[1],
        errorType = _types[2];
      next({
        type: requestType,
        payload: payload
      });

      // Passing the authenticated boolean back in our data will let us distinguish
      // between normal and secret quotes
      return callApi(authToken, endpoint, authenticated, method, payload, email, isBlob).then(function (response) {
        return next({
          response: isBlob === true ? response.blob : response.json,
          metadata: response.metadata,
          authenticated: authenticated,
          payload: payload,
          type: successType,
          options: options,
          email: email,
          filename: response.filename
        });
      }, function (error, response) {
        var resp = {
          type: errorType
        };
        if (error.status === 409) {
          // dispatch(showToastNotification('This data is out of date. Please refresh and try again!', 'ERROR', null, 5000));
        }
        if (error.messages) {
          resp.messages = error.messages;
        } else if (error.message) {
          resp.messages = [error.message];
        } else {
          resp.messages = ['There was an error.'];
        }
        return next(resp);
        //TODO: We need a special way to handle 401s caused by expired tokens
        //That way the user will get kicked back to the login screen if needed
      });
    };
  };
};