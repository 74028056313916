import { Button, FormControl, Grid, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { customValidationRules } from '../../utilities/answerValidation';
import { hasPermission } from '../auth/authOperations';
import { EDIT_ANSWERS } from '../auth/permissions';
import CustomSelect from '@survey/common/dist/components/form-controls/CustomSelect';
import CustomTextField from '@survey/common/dist/components/form-controls/CustomTextField';

const styles = ({ spacing }) => ({
    form: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
        marginLeft: spacing(5),
        marginRight: spacing(5),
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    formControl: {
        display: 'block',
        width: '100%',
    },
    textField: {
        width: '85%',
        fontSize: 12,
    },
    gridPaper: {
        width: '100%',
    },
    fab: {
        top: spacing(7),
        right: spacing(1),
        float: 'right',
        zIndex: 100,
    },
    button: {
        margin: spacing(1),
    },
    questionsPaper: {
        marginTop: spacing(2),
        padding: spacing(2),
    },
    centerText: {
        textAlign: 'center',
    },
    labelText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.6375rem',
        marginBottom: '0.5rem',
        marginTop: '0.5rem',
    },
});

const typeOptions = [
    {
        value: 'List',
        label: 'List',
        groups: 'List',
    },
    {
        value: 'API',
        label: 'API',
        groups: 'API',
    },
];

const apiOptions = [
    { value: 'technologiesList', label: 'Technologies' },
    { value: 'countriesList', label: 'Countries' },
    { value: 'vendorsList', label: 'Vendors' },
    { value: 'productsList', label: 'Products' },
    { value: 'regionsList', label: 'Regions' },
    { value: 'entityHospitals', label: 'Hospitals' },
    { value: 'entityAmbulatories', label: 'Ambulatories' },
    { value: 'entitySubAcutes', label: 'Sub Acutes' },
    { value: 'entityHomeHealths', label: 'Home Healths' },
    { value: 'entityFreeStandingDataCenters', label: 'Free Standing Data Centers' },
    { value: 'entityInHospitalDataCenters', label: 'In Hospital DataCenters' },
    { value: 'entityUntetheredAmbulatories', label: 'Untethered Ambulatories' },
];

class AnswerForm extends Component {
    constructor(props) {
        super(props);

        let answer = props.answer;

        this.state = {
            answerName: answer && answer.answerName ? answer.answerName : '',
            answerDescription: answer && answer.answerDescription ? answer.answerDescription : '',
            rows: answer && answer.answers ? answer.answers.map((a, index) => ({ id: a.id, idx: index, label: a.label, value: a.value, groups: a.groups, translations: a.translations ? a.translations : {}, answerTranslation: a.answerTranslation ? a.answerTranslation :[]})) : [],
            type: answer && answer.type ? { label: answer.type, value: answer.type } : { label: 'List', value: 'List', groups: 'List' },
            apiName: answer && answer.apiName ? find(apiOptions, { value: answer.apiName }) : '',
        };

        ['updateRow', 'deleteRow', 'verifyRow', 'onSubmit', 'updateSelectedValueField'].map((item) => (this[item] = this[item].bind(this)));
    }

    componentDidMount() {
        /* Add all of the custom validation rules */
        Object.keys(customValidationRules).forEach((ruleName) => {
            ValidatorForm.addValidationRule(ruleName, customValidationRules[ruleName]);
        });
    }

    addRow() {
        const rows = [...this.state.rows];
        rows.push({ id: 0, idx: rows.length, label: '', value: '', groups: [], translations: {}, answerTranslation:[] });

        this.setState({ rows });
    }

    deleteRow(idx) {
        const rows = [...this.state.rows];
        const ridx = rows.findIndex((r) => r.idx === idx);
        rows.splice(ridx, 1);

        this.setState({ rows });
    }

    updateRow(idx, field, value) {
        const rows = [...this.state.rows];

        if (field === 'groups') {
            const groupsValue = value.split(',');
            rows[idx][field] = groupsValue;
        } else {
            rows[idx][field] = value;
        }

        this.setState({ rows });
    }

    verifyRow(rows) {
        let hasFailed = false;

        rows.forEach((row) => {
            if (!row.label.length) {
                this.props.handleToastMessage('A label must be provided before saving changes!', true);
                hasFailed = true;
            } else if (!row.value) {
                this.props.handleToastMessage('An value must be selected before saving changes!', true);
                hasFailed = true;
            }
        });

        return !hasFailed;
    }

    updateSelectedValueField(field) {
        return (event, val) => {
            this.setState({
                [field]: val,
            });
        };
    }

    onSubmit() {
        let data = { ...this.state };

        if (data.type.value === 'List') {
            if (data.rows.length === 0) {
                this.props.handleToastMessage('At least one answer is required before saving changes!', true);
                return;
            }

            data.type = 'List';
            data.apiName = '';

            /* Make sure all the data is valid before saving */
            if (!this.verifyRow(data.rows)) return;

            data.answers = data.rows.map((r) => {
                delete r.idx;
                return r;
            });
        } else {
            data.type = 'API';
            data.answers = [];
            data.apiName = data.apiName.value;
        }

        delete data.rows;
        console.log('answer submit data', data);
        this.props.onSubmit(data);
    }

    render() {
        const { classes, answerQuestionReferences, answerTechQuestionReferences, permissions, onError, history } = this.props;
        const { rows, answerName, type, apiName, answerDescription } = this.state;
        const disabled = !hasPermission(EDIT_ANSWERS, permissions);

        return (
            <ValidatorForm onSubmit={this.onSubmit} onError={onError} className={classes.form}>
                <Grid container spacing={10}>
                    <Grid item sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                            <CustomTextField
                                disabled={disabled}
                                key="Answer Name"
                                name="Answer Name"
                                fullWidth
                                margin="dense"
                                multiline={true}
                                maxRows="4"
                                required={true}
                                value={answerName}
                                label="Answer Name"
                                onChange={(event) => this.setState({ answerName: event.target.value })}
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                disabled={disabled}
                                key="Answer Description"
                                name="Answer Description"
                                fullWidth
                                margin="dense"
                                multiline={true}
                                maxRows="4"
                                label="Answer Description"
                                value={answerDescription}
                                onChange={(event) => this.setState({ answerDescription: event.target.value })}
                            />
                        </FormControl>
                        <CustomSelect
                            name="type"
                            label="Answer Name"
                            className={classes.select}
                            value={type ? type : ''}
                            options={typeOptions}
                            disabled={disabled}
                            onChange={this.updateSelectedValueField}
                            required={true}
                        />
                        {type && type.value === 'API' && (
                            <CustomSelect name="apiName" label="API" className={classes.select} value={apiName} options={apiOptions} disabled={disabled} onChange={this.updateSelectedValueField} required={true} />
                        )}
                    </Grid>
                    {type && type.value === 'List' && (
                        <Grid item sm={12} md={6}>
                            <Typography type="body1" className={classes.labelText}>
                                Answers
                            </Typography>
                            <Paper className={classes.gridPaper} square>
                                <Table>
                                    <colgroup>
                                        <col style={{ width: '40%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '20%' }} />
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.centerText}>Description</TableCell>
                                            <TableCell className={classes.centerText}>Value</TableCell>
                                            <TableCell className={classes.centerText}>Groups</TableCell>
                                            <TableCell className={classes.centerText}>
                                                <Button disabled={disabled} variant="contained" color="primary" className={classes.button} onClick={() => this.addRow()}>
                                                    Add Answer
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((r) => (
                                            <TableRow key={r.idx}>
                                                <TableCell style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                                                    <TextField disabled={disabled} style={{ width: '100%' }} value={r.label} onChange={(e) => this.updateRow(r.idx, 'label', e.target.value)} />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField disabled={disabled} value={r.value} onChange={(e) => this.updateRow(r.idx, 'value', e.target.value)} />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField disabled={disabled} value={r.groups} onChange={(e) => this.updateRow(r.idx, 'groups', e.target.value)} />
                                                </TableCell>
                                                <TableCell>
                                                    {!disabled && (
                                                        <Button color="primary" className={classes.button} onClick={() => this.deleteRow(r.idx)}>
                                                            Delete
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item sm={12} md={6}>
                        {answerQuestionReferences.length > 0 && (
                            <Paper className={classes.questionsPaper}>
                                <Typography variant="subtitle1">Questions</Typography>
                                <List dense={true}>
                                    {answerQuestionReferences.map((i, index) => (
                                        <ListItem button key={index}>
                                            <ListItemText primary={`${i.questionDescription}`} onClick={() => history.push(`/questions/${i.questionID}`)} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}
                        {answerTechQuestionReferences.length > 0 && (
                            <Paper className={classes.questionsPaper}>
                                <Typography variant="subtitle1">Technology Questions</Typography>
                                <List dense={true}>
                                    {answerTechQuestionReferences.map((i, index) => (
                                        <ListItem button key={index}>
                                            <ListItemText primary={`${i.questionDescription}`} onClick={() => history.push(`/technologyQuestions/${i.questionID}`)} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}
                    </Grid>
                    <Grid item style={{ width: '100%', paddingTop: 0, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="text" color="primary" className={classes.button} onClick={() => history.goBack()}>
                            Back
                        </Button>
                        <Button type="submit" disabled={!hasPermission(EDIT_ANSWERS, permissions)} variant="contained" color="primary" className={classes.button}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        );
    }
}

AnswerForm.propTypes = {
    answer: PropTypes.object,
    answerReferences: PropTypes.array,
};

export default withStyles(styles)(AnswerForm);
