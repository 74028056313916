"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTechnologiesList = exports.getTechnologies = exports.GET_TECHNOLOGIES_SUCCESS = exports.GET_TECHNOLOGIES_REQUEST = exports.GET_TECHNOLOGIES_LIST_SUCCESS = exports.GET_TECHNOLOGIES_LIST_REQUEST = exports.GET_TECHNOLOGIES_LIST_FAILURE = exports.GET_TECHNOLOGIES_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_TECHNOLOGIES_REQUEST = exports.GET_TECHNOLOGIES_REQUEST = 'GET_TECHNOLOGIES_REQUEST';
var GET_TECHNOLOGIES_SUCCESS = exports.GET_TECHNOLOGIES_SUCCESS = 'GET_TECHNOLOGIES_SUCCESS';
var GET_TECHNOLOGIES_FAILURE = exports.GET_TECHNOLOGIES_FAILURE = 'GET_TECHNOLOGIES_FAILURE';
var GET_TECHNOLOGIES_LIST_REQUEST = exports.GET_TECHNOLOGIES_LIST_REQUEST = 'GET_TECHNOLOGIES_LIST_REQUEST';
var GET_TECHNOLOGIES_LIST_SUCCESS = exports.GET_TECHNOLOGIES_LIST_SUCCESS = 'GET_TECHNOLOGIES_LIST_SUCCESS';
var GET_TECHNOLOGIES_LIST_FAILURE = exports.GET_TECHNOLOGIES_LIST_FAILURE = 'GET_TECHNOLOGIES_LIST_FAILURE';
var getTechnologies = exports.getTechnologies = function getTechnologies() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_TECHNOLOGIES_REQUEST, GET_TECHNOLOGIES_SUCCESS, GET_TECHNOLOGIES_FAILURE],
    authenticated: true,
    endpoint: "technologies",
    method: 'GET'
  });
};
var getTechnologiesList = exports.getTechnologiesList = function getTechnologiesList() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_TECHNOLOGIES_LIST_REQUEST, GET_TECHNOLOGIES_LIST_SUCCESS, GET_TECHNOLOGIES_LIST_FAILURE],
    authenticated: true,
    endpoint: 'technologies/list',
    method: 'GET'
  });
};