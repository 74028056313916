"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _fileSaver = require("file-saver");
var _surveys = require("../actions/surveys.actions");
var initialState = _immutable["default"].Map({
  surveys: [],
  survey: {},
  surveyType: {},
  surveyScore: [],
  surveyAMAMScore: [],
  isLoading: false,
  isApiError: false,
  isSaving: false,
  isScoreLoading: false,
  wizardSurvey: {},
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _surveys.CREATE_WIZARD_SURVEY_REQUEST:
      return state.set('isLoading', true);
    case _surveys.CREATE_WIZARD_SURVEY_SUCCESS:
      return state.set('isLoading', false).set('wizardSurvey', action.response);
    case _surveys.CREATE_WIZARD_SURVEY_FAILURE:
      return state.set('isLoading', false).set('isApiError', true).set('error', action.response);
    case _surveys.CREATE_SURVEY_REQUEST:
      return state.set('isLoading', true);
    case _surveys.CREATE_SURVEY_SUCCESS:
      var surveys = [].concat(state.get('surveys'));
      if (action.response) {
        surveys.push(action.response);
      }
      return state.set('surveys', surveys).set('isLoading', false);
    case _surveys.SAVE_SURVEY_REQUEST:
      return state.set('isSaving', true);
    case _surveys.SAVE_SURVEY_SUCCESS:
      return state.set('isSaving', false).set('survey', action.response ? action.response : {});
    case _surveys.SAVE_SURVEY_FAILURE:
      return state.set('isSaving', false).set('error', action.messages).set('isApiError', true);
    case _surveys.SET_ASSESSMENT_SECTIONS_COMPLETE_REQUEST:
      return state.set('isLoading', true);
    case _surveys.SET_ASSESSMENT_SECTIONS_COMPLETE_SUCCESS:
      return state.set('isLoading', false).set('survey', action.response ? action.response : {});
    case _surveys.SET_ASSESSMENT_SECTIONS_COMPLETE_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.SAVE_SURVEY_CHANGES_REQUEST:
      return state.set('isSaving', true);
    case _surveys.SAVE_SURVEY_CHANGES_SUCCESS:
      return state.set('isSaving', false).set('survey', action.response ? action.response : {});
    case _surveys.SAVE_SURVEY_CHANGES_FAILURE:
      return state.set('isSaving', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_SURVEYS_REQUEST:
      return state.set('surveys', []).set('errors', []).set('isLoading', true);
    case _surveys.GET_SURVEYS_SUCCESS:
      return state.set('surveys', action.response).set('isLoading', false);
    case _surveys.GET_SURVEYS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_SURVEY_REQUEST:
      return state.set('survey', {}).set('errors', []).set('isLoading', true);
    case _surveys.GET_SURVEY_SUCCESS:
      return state.set('survey', action.response).set('isLoading', false);
    case _surveys.GET_SURVEY_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_SURVEY_TYPE_REQUEST:
      return state.set('surveyType', {}).set('errors', []).set('isLoading', true);
    case _surveys.GET_SURVEY_TYPE_SUCCESS:
      return state.set('surveyType', action.response).set('isLoading', false);
    case _surveys.GET_SURVEY_TYPE_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_SURVEY_TYPES_REQUEST:
      return state.set('surveyTypes', {}).set('errors', []).set('isLoading', true);
    case _surveys.GET_SURVEY_TYPES_SUCCESS:
      return state.set('surveyTypes', action.response).set('isLoading', false);
    case _surveys.GET_SURVEY_TYPES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_USER_SURVEYS_REQUEST:
      return state.set('mySurveys', []).set('errors', []).set('isLoading', true);
    case _surveys.GET_USER_SURVEYS_SUCCESS:
      return state.set('mySurveys', action.response).set('isLoading', false);
    case _surveys.GET_USER_SURVEYS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_SURVEY_SCORE_REQUEST:
      return state.set('surveyScore', []).set('errors', []).set('isScoreLoading', true);
    case _surveys.GET_SURVEY_SCORE_SUCCESS:
      return state.set('surveyScore', action.response).set('isScoreLoading', false);
    case _surveys.GET_SURVEY_SCORE_FAILURE:
      return state.set('isScoreLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.GET_SURVEY_AMAM_SCORE_REQUEST:
      return state.set('surveyAMAMScore', []).set('errors', []).set('isScoreLoading', true);
    case _surveys.GET_SURVEY_AMAM_SCORE_SUCCESS:
      return state.set('surveyAMAMScore', action.response).set('isScoreLoading', false);
    case _surveys.GET_SURVEY_AMAM_SCORE_FAILURE:
      return state.set('isScoreLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.SET_SURVEY:
      return state.set('survey', action.response);
    case _surveys.DOWNLOAD_SURVEY_TYPE_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _surveys.DOWNLOAD_SURVEY_TYPE_SUCCESS:
      {
        console.log('DOWNLOAD_SURVEY_TYPE_SUCCESS', action);
        (0, _fileSaver.saveAs)(action.response, action.filename);
        return state.set('isLoading', false);
      }
    case _surveys.DOWNLOAD_SURVEY_TYPE_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.DOWNLOAD_SURVEY_PDF_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _surveys.DOWNLOAD_SURVEY_PDF_SUCCESS:
      {
        console.log('DOWNLOAD_SURVEY_PDF_SUCCESS', action);
        (0, _fileSaver.saveAs)(action.response, action.filename);
        return state.set('isLoading', false);
      }
    case _surveys.DOWNLOAD_SURVEY_PDF_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _surveys.SAVE_SURVEY_FILE_REQUEST:
      return state.set('isSaving', true);
    case _surveys.SAVE_SURVEY_FILE_SUCCESS:
      return state.set('isSaving', false);
    case _surveys.SAVE_SURVEY_FILE_FAILURE:
      return state.set('isSaving', false);
    case _surveys.GET_SURVEY_FILE_REQUEST:
      return state.set('isSaving', true);
    case _surveys.GET_SURVEY_FILE_SUCCESS:
      return state.set('isSaving', false);
    case _surveys.GET_SURVEY_FILE_FAILURE:
      return state.set('isSaving', false);
    case _surveys.GET_SURVEY_FILE_DETAIL_REQUEST:
      return state.set('isLoading', true);
    case _surveys.GET_SURVEY_FILE_DETAIL_SUCCESS:
      return state.set('isLoading', false);
    case _surveys.GET_SURVEY_FILE_DETAIL_FAILURE:
      return state.set('isLoading', false);
    default:
      return state;
  }
};