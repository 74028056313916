"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateQuestion = exports.applySurveyTypeOverrides = void 0;
var _lodash = require("lodash");
var updateQuestion = exports.updateQuestion = function updateQuestion(stQ, q, language) {
  var question = (0, _lodash.cloneDeep)(q);
  if (stQ.sortOrder !== null) {
    // console.log('sort order of', question.questionID, stQ.sortOrder);
    question.sortOrder = stQ.sortOrder;
    question.isRequired = stQ.isRequired;
  }
  question.isRequired = stQ.isRequired;
  if (language && question.translations && question.translations[language]) {
    if (question.translations[language].questionDescription !== '') question.questionDescription = question.translations[language].questionDescription;
    if (question.translations[language].additionalDescription !== '') question.additionalDescription = question.translations[language].additionalDescription;
  }
  if (language && question.hintTranslations && question.hintTranslations[language]) {
    if (question.hintTranslations[language] !== '') question.hint = question.hintTranslations[language];
  }
  return question;
};
var _getBranchingQuestions = function getBranchingQuestions(q) {
  var bqs = [];
  if (!q.branchingQuestions.length) {
    return bqs;
  }
  bqs.push.apply(bqs, q.branchingQuestions);
  q.branchingQuestions.forEach(function (question) {
    bqs.push.apply(bqs, _getBranchingQuestions(question));
  });
  return bqs;
};
var applySurveyTypeOverrides = exports.applySurveyTypeOverrides = function applySurveyTypeOverrides(surveyType, questions, technologyQuestions) {
  var updatedQuestions = surveyType.questions.reduce(function (all, q) {
    var branchingQuestions = [];
    if (q.branchingQuestions.length) {
      branchingQuestions = _getBranchingQuestions(q);
    }
    var allQuestions = [q].concat(branchingQuestions).map(function (question) {
      var questionData = (0, _lodash.find)(questions, {
        questionID: question.questionID
      });
      if (questionData) {
        return updateQuestion(q, questionData);
      }
    });
    all.push.apply(all, allQuestions);
    return all;
  }, []);
  var updatedTechQuestions = surveyType.technologyQuestions.reduce(function (all, q) {
    var branchingQuestions = [];
    if (q.branchingQuestions.length) {
      branchingQuestions = _getBranchingQuestions(q);
    }
    var allQuestions = [q].concat(branchingQuestions).map(function (question) {
      var questionData = (0, _lodash.find)(technologyQuestions, {
        questionID: question.questionID
      });
      if (questionData) {
        return updateQuestion(q, questionData);
      }
    });
    all.push.apply(all, allQuestions);
    return all;
  }, []);
  return {
    questions: updatedQuestions,
    technologyQuestions: updatedTechQuestions
  };
};