"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventBus = void 0;
var EventBus = exports.EventBus = {
  on: function on(event, callback) {
    document.addEventListener(event, function (e) {
      return callback(e.detail);
    });
  },
  dispatch: function dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, {
      detail: data
    }));
  },
  remove: function remove(event, callback) {
    document.removeEventListener(event, callback);
  }
};