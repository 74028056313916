"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadQuestions = exports.saveQuestion = exports.getQuestions = exports.deleteQuestion = exports.createQuestion = exports.UPLOAD_QUESTION_SUCCESS = exports.UPLOAD_QUESTION_REQUEST = exports.UPLOAD_QUESTION_FAILURE = exports.SAVE_QUESTION_SUCCESS = exports.SAVE_QUESTION_REQUEST = exports.SAVE_QUESTION_FAILURE = exports.GET_QUESTIONS_SUCCESS = exports.GET_QUESTIONS_REQUEST = exports.GET_QUESTIONS_FAILURE = exports.DELETE_QUESTION_SUCCESS = exports.DELETE_QUESTION_REQUEST = exports.DELETE_QUESTION_FAILURE = exports.CREATE_QUESTION_SUCCESS = exports.CREATE_QUESTION_REQUEST = exports.CREATE_QUESTION_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_QUESTIONS_REQUEST = exports.GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
var GET_QUESTIONS_SUCCESS = exports.GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
var GET_QUESTIONS_FAILURE = exports.GET_QUESTIONS_FAILURE = 'GET_QUESTIONS_FAILURE';
var CREATE_QUESTION_REQUEST = exports.CREATE_QUESTION_REQUEST = 'CREATE_QUESTION_REQUEST';
var CREATE_QUESTION_SUCCESS = exports.CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
var CREATE_QUESTION_FAILURE = exports.CREATE_QUESTION_FAILURE = 'CREATE_QUESTION_FAILURE';
var SAVE_QUESTION_REQUEST = exports.SAVE_QUESTION_REQUEST = 'SAVE_QUESTION_REQUEST';
var SAVE_QUESTION_SUCCESS = exports.SAVE_QUESTION_SUCCESS = 'SAVE_QUESTION_SUCCESS';
var SAVE_QUESTION_FAILURE = exports.SAVE_QUESTION_FAILURE = 'SAVE_QUESTION_FAILURE';
var DELETE_QUESTION_REQUEST = exports.DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
var DELETE_QUESTION_SUCCESS = exports.DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
var DELETE_QUESTION_FAILURE = exports.DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';
var UPLOAD_QUESTION_REQUEST = exports.UPLOAD_QUESTION_REQUEST = 'UPLOAD_QUESTION_REQUEST';
var UPLOAD_QUESTION_SUCCESS = exports.UPLOAD_QUESTION_SUCCESS = 'UPLOAD_QUESTION_SUCCESS';
var UPLOAD_QUESTION_FAILURE = exports.UPLOAD_QUESTION_FAILURE = 'UPLOAD_QUESTION_FAILURE';
var getQuestions = exports.getQuestions = function getQuestions() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAILURE],
    authenticated: true,
    endpoint: "questions",
    method: 'GET'
  });
};
var uploadQuestions = exports.uploadQuestions = function uploadQuestions(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [UPLOAD_QUESTION_REQUEST, UPLOAD_QUESTION_SUCCESS, UPLOAD_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "questions/upload",
    method: 'POST',
    payload: data
  });
};
var createQuestion = exports.createQuestion = function createQuestion(data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [CREATE_QUESTION_REQUEST, CREATE_QUESTION_SUCCESS, CREATE_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "questions",
    method: 'POST',
    payload: data
  });
};
var saveQuestion = exports.saveQuestion = function saveQuestion(question_id, data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [SAVE_QUESTION_REQUEST, SAVE_QUESTION_SUCCESS, SAVE_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "questions/".concat(question_id),
    method: 'PUT',
    payload: data
  });
};
var deleteQuestion = exports.deleteQuestion = function deleteQuestion(question_id) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [DELETE_QUESTION_REQUEST, DELETE_QUESTION_SUCCESS, DELETE_QUESTION_FAILURE],
    authenticated: true,
    endpoint: "questions/".concat(question_id),
    method: 'DELETE'
  });
};