"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTechnologyQuestionsTabs = void 0;
var _lodash = require("lodash");
var createTechnologyQuestionsTabs = exports.createTechnologyQuestionsTabs = function createTechnologyQuestionsTabs(surveyType, technologies) {
  if (!surveyType || !technologies) {
    return [];
  }
  return surveyType.technologyQuestions.reduce(function (tabsList, q) {
    if ((0, _lodash.findIndex)(tabsList, {
      tabId: q.technologyID
    }) === -1) {
      var tech = (0, _lodash.find)(technologies, {
        technologyID: q.technologyID
      });
      tabsList.push({
        tabId: q.technologyID,
        tabName: tech ? tech.technologyName : '',
        pageId: tech ? 'tq_' + tech.technologyGroupID : '',
        haEntityTypeId: 0
      });
    }
    return tabsList;
  }, []);
};