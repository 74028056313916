"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVisibleTabs = exports.getVisiblePages = exports.getPages = exports.getCurrentPageTabs = exports.GET_PAGES_SUCCESS = exports.GET_PAGES_REQUEST = exports.GET_PAGES_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _lodash = require("lodash");
var _api = require("../middleware/api");
var GET_PAGES_REQUEST = exports.GET_PAGES_REQUEST = 'GET_PAGES_REQUEST';
var GET_PAGES_SUCCESS = exports.GET_PAGES_SUCCESS = 'GET_PAGES_SUCCESS';
var GET_PAGES_FAILURE = exports.GET_PAGES_FAILURE = 'GET_PAGES_FAILURE';
var getPages = exports.getPages = function getPages() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_PAGES_REQUEST, GET_PAGES_SUCCESS, GET_PAGES_FAILURE],
    authenticated: true,
    endpoint: "pages",
    method: 'GET'
  });
};
var getVisibleTabs = exports.getVisibleTabs = function getVisibleTabs(tabs, questions, questionIds) {
  /* Determine whether these are questions or technology questions */
  var isTechQuestions = questions.length && 'technologies' in questions[0];
  questions = questions.filter(function (q) {
    if (!q.active) return false;
    return questionIds.findIndex(function (qId) {
      return qId.questionID === q.questionID;
    }) > -1;
  });
  return tabs.filter(function (i) {
    if (isTechQuestions) {
      return questionIds.findIndex(function (q) {
        return q.technologyID === i.tabId;
      }) > -1;
    } else {
      return questions.findIndex(function (q) {
        return q.tabID === i.tabId;
      }) > -1;
    }
  });
};
var getVisiblePages = exports.getVisiblePages = function getVisiblePages(pages, tabs, questions, questionIds) {
  tabs = getVisibleTabs(tabs, questions, questionIds);
  return pages.filter(function (i) {
    return tabs.findIndex(function (t) {
      return t.pageId === i.pageId;
    }) > -1;
  });
};
var getCurrentPageTabs = exports.getCurrentPageTabs = function getCurrentPageTabs(pageId, tabs, questions, questionIds, techQuestions, techQuestionIds) {
  if (typeof pageId === 'string' && pageId.startsWith('tq_')) {
    tabs = (0, _lodash.filter)(tabs, {
      pageId: pageId
    });
    return getVisibleTabs(tabs, techQuestions, techQuestionIds);
  }
  tabs = (0, _lodash.filter)(tabs, {
    pageId: Number(pageId)
  });
  return getVisibleTabs(tabs, questions, questionIds);
};