"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
var _pages = require("../actions/pages.actions");
var initialState = _immutable["default"].Map({
  pages: [],
  isLoading: false,
  isApiError: false,
  errors: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _pages.GET_PAGES_REQUEST:
      return state.set('pages', []).set('errors', []).set('isLoading', true);
    case _pages.GET_PAGES_SUCCESS:
      return state.set('pages', action.response).set('isLoading', false);
    case _pages.GET_PAGES_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    default:
      return state;
  }
};