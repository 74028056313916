"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _immutable = _interopRequireDefault(require("immutable"));
var _products = require("../actions/products.actions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var initialState = _immutable["default"].Map({
  products: [],
  isLoading: false,
  errors: [],
  product: {},
  isApiError: false,
  productsList: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case _products.GET_PRODUCTS_REQUEST:
      return state.set('products', []).set('errors', []).set('isLoading', true);
    case _products.GET_PRODUCTS_SUCCESS:
      return state.set('products', action.response).set('isLoading', false);
    case _products.GET_PRODUCTS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _products.GET_PRODUCTS_LIST_REQUEST:
      return state.set('productsList', []).set('errors', []).set('isLoading', true);
    case _products.GET_PRODUCTS_LIST_SUCCESS:
      var sortedList = [].concat(action.response);
      sortedList.sort(function (a, b) {
        var nameA = a.label.toLowerCase(),
          nameB = b.label.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      return state.set('productsList', sortedList).set('isLoading', false);
    case _products.GET_PRODUCTS_LIST_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _products.SELECT_PRODUCT_REQUEST:
      return state.set('product', {}).set('errors', []).set('isLoading', true);
    case _products.SELECT_PRODUCT_SUCCESS:
      return state.set('product', action.response).set('isLoading', false);
    case _products.SELECT_PRODUCT_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _products.CREATE_PRODUCT_REQUEST:
      return state.set('isLoading', true);
    case _products.CREATE_PRODUCT_SUCCESS:
      var newProduct = action.response;
      var products = [].concat(state.get('products'));
      products.push(newProduct);
      return state.set('products', products).set('isLoading', false);
    case _products.CREATE_PRODUCT_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _products.SAVE_PRODUCT_REQUEST:
      return state.set('isLoading', true);
    case _products.SAVE_PRODUCT_SUCCESS:
      return state.set('isLoading', false);
    case _products.SAVE_PRODUCT_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _products.CONSOLIDATE_PRODUCTS_REQUEST:
      return state.set('isLoading', true);
    case _products.CONSOLIDATE_PRODUCTS_SUCCESS:
      return state.set('isLoading', false);
    case _products.CONSOLIDATE_PRODUCTS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _products.DELETE_PRODUCT_REQUEST:
      return state.set('isLoading', true);
    case _products.DELETE_PRODUCT_SUCCESS:
      var product = _objectSpread({}, state.get('product'));
      product.status = 'DELETE';
      return state.set('isLoading', false).set('product', product);
    case _products.DELETE_PRODUCT_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    default:
      return state;
  }
};