import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Avatar, Button, Paper, Toolbar, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { cloneDeep, filter, find } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { hasPermission } from '../auth/authOperations';
import { EDIT_ANSWERS } from '../auth/permissions';
import { handleToastMessage } from '../layout/layout.actions';
import AnswerForm from './answerForm.component';
import { createAnswer, deleteAnswer, getAnswers, saveAnswer, getLanguages } from '@survey/common/dist/actions/answers.actions';
import { getPages } from '@survey/common/dist/actions/pages.actions';
import { getTabs } from '@survey/common/dist/actions/tabs.actions';
import { getQuestions } from '@survey/common/dist/actions/questions.actions';
import { getTechnologyQuestions } from '@survey/common/dist/actions/technologyQuestions.actions';
import LoadingOverlay from '../layout/loadingOverlay.component';
import AnswerTranslation from '@survey/common/dist/components/dialogs/AnswerTranslation';
const styles = (theme) => ({
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  answer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: theme.spacing(1),
  },
  heading: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
});

class AnswerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { showTranslationDialog: false };
    this.onSubmit = this.onSubmit.bind(this);
    this.deleteAnswer = this.deleteAnswer.bind(this);
    this.updateTranslation = this.updateTranslation.bind(this);
  }

  componentDidMount() {
    this.props.getPages();
    this.props.getTabs();
    this.props.getQuestions();
    this.props.getTechnologyQuestions();
    this.props.getAnswers();
    this.props.getLanguages();
  }

  async onSubmit(data) {
    if (this.props.match.params.answerId === 'new') {
      let createResponse = await this.props.createAnswer(data);
      if (createResponse.type === 'CREATE_ANSWER_SUCCESS') {
        this.props.history.goBack();
        this.props.handleToastMessage('SUCCESS', false);
      } else {
        this.props.handleToastMessage('ERROR', true);
      }
    } else {
      data.answerID = parseInt(this.props.match.params.answerId);
      let createResponse = await this.props.saveAnswer(this.props.match.params.answerId, data);
      if (createResponse.type === 'SAVE_ANSWER_SUCCESS') {
        this.props.history.goBack();
        this.props.handleToastMessage('SUCCESS', false);
      } else {
        this.props.handleToastMessage('ERROR', true);
      }
    }
  }
  updateTranslation(data) {
    const { answers, match } = this.props;
    let answer = find(answers, { answerID: parseInt(match.params.answerId, 10) });
    answer.answers = data.answers;
    console.log('updateTranslation', answer);
    this.setState({ showTranslationDialog: false });
  }
  async deleteAnswer() {
    let deleteResponse = await this.props.deleteAnswer(this.props.match.params.answerId);
    if (deleteResponse.type === 'GET_ANSWERS_SUCCESS') {
      this.props.history.goBack();
      this.props.handleToastMessage('SUCCESS', false);
    } else {
      this.props.handleToastMessage('ERROR', true);
    }
  }

  render() {
    const { classes, handleToastMessage, history, pages, tabs, questions, answers, isLoading, match, permissions, languages } = this.props;
    const answer = find(answers, { answerID: parseInt(match.params.answerId, 10) });
    const disabled = !hasPermission(EDIT_ANSWERS, permissions);

    let answerQuestionReferences = [];
    let answerTechQuestionReferences = [];

    if (answer) {
      answerQuestionReferences = filter(this.props.questions, (question) => {
        return question.answerID === answer.answerID;
      });

      answerTechQuestionReferences = filter(this.props.technologyQuestions, (question) => {
        return question.answerID === answer.answerID;
      });
    }

    return isLoading ? (
      <LoadingOverlay />
    ) : (
      <Paper className={classes.paper} square>
        <AppBar position="static" color="default">
          <Toolbar className={classes.toolBar}>
            <div className={classes.answer}>
              <Avatar className={classes.avatar}>
                <FontAwesomeIcon icon="list-ul" />
              </Avatar>
              <div>
                <Typography variant="h6" color="inherit" style={{ alignSelf: 'center' }} onClick={() => history.push('/answers')}>
                  Answers
                </Typography>
                <Typography variant="subtitle1" color="inherit">
                  Answer ID: {answer ? answer.answerID : 'New'}
                </Typography>
              </div>
            </div>
            {!disabled && answer && (
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    console.log('answerItem', answer);
                    this.setState({ showTranslationDialog: true });
                  }}
                >
                  Translation
                </Button>
                {answer.active ? (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.onSubmit({ ...answer, active: false })}>
                    De-Activate
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" className={classes.button} onClick={() => this.onSubmit({ ...answer, active: true })}>
                    Activate
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.deleteAnswer}
                  disabled={answerQuestionReferences.length > 0 || answerTechQuestionReferences.length > 0}
                >
                  Delete
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>
        <AnswerForm
          answer={answer}
          pages={pages}
          tabs={tabs}
          questions={questions}
          answerQuestionReferences={answerQuestionReferences}
          answerTechQuestionReferences={answerTechQuestionReferences}
          history={history}
          onSubmit={this.onSubmit}
          onError={(errors) => console.log(errors)}
          handleToastMessage={handleToastMessage}
          permissions={permissions}
        />
       <AnswerTranslation onClose={() => this.setState({ showTranslationDialog: false })} answerItem={answer} onConfirm={this.updateTranslation} open={this.state.showTranslationDialog} languages={languages} />
      </Paper>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    pages: state.pages.get('pages'),
    tabs: state.tabs.get('tabs'),
    answers: state.answers.get('answers'),
    questions: state.questions.get('questions'),
    technologyQuestions: state.technologyQuestions.get('technologyQuestions'),
    isLoading: state.pages.get('isLoading') || state.tabs.get('isLoading') || state.questions.get('isLoading') || state.answers.get('isLoading'),
    permissions: state.auth.get('permissions'),
    languages: state.answers.get('languages')
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, {
    getPages,
    getTabs,
    getQuestions,
    getTechnologyQuestions,
    getAnswers,
    createAnswer,
    saveAnswer,
    deleteAnswer,
    handleToastMessage,
    getLanguages
  })(AnswerContainer)
);
