"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _immutable = _interopRequireDefault(require("immutable"));
var _products = require("../actions/products.actions");
var _vendors = require("../actions/vendors.actions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var initialState = _immutable["default"].Map({
  vendors: [],
  acquireVendors: [],
  totalVendorsLoaded: 0,
  isLoading: false,
  isApiError: false,
  isContactsLoading: false,
  isVendorsDialogLoading: false,
  isProductsDialogLoading: false,
  errors: [],
  vendor: {},
  contacts: {},
  vendorProducts: [],
  vendorsList: []
});
var _default = exports["default"] = function _default() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var vendorCopy = {};
  var vendorsCopy = [];
  var vendors = [];
  switch (action.type) {
    case _vendors.GET_VENDORS_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _vendors.GET_VENDORS_SUCCESS:
      /*vendorsCopy = [...state.get('vendors')];
        vendors = [...vendorsCopy, ...action.response];*/

      vendors = [].concat(action.response);
      console.log('VENDORS OUTPUT', vendors);
      var sortedList = vendors;
      sortedList.sort(function (a, b) {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      return state.set('vendors', sortedList).set('totalVendorsLoaded', sortedList.length).set('isLoading', false);
    case _vendors.GET_VENDORS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.GET_FILTERED_VENDORS_REQUEST:
      return state.set('totalVendorsLoading', 0).set('errors', []).set('isLoading', true);
    case _vendors.GET_FILTERED_VENDORS_SUCCESS:
      return state.set('vendors', action.response).set('totalVendorsLoaded', action.response.length).set('isLoading', false);
    case _vendors.GET_FILTERED_VENDORS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.GET_VENDORS_LIST_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _vendors.GET_VENDORS_LIST_SUCCESS:
      var sortedList = [].concat(action.response);
      sortedList.sort(function (a, b) {
        var nameA = a.label.toLowerCase(),
          nameB = b.label.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
      return state.set('vendorsList', sortedList).set('isLoading', false);
    case _vendors.GET_VENDORS_LIST_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.GET_VENDORPRODUCTS_SYNC_LOG_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _vendors.GET_VENDORPRODUCTS_SYNC_LOG_SUCCESS:
      return state.set('syncLogs', action.response).set('isLoading', false);
    case _vendors.GET_VENDORPRODUCTS_SYNC_LOG_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.SET_VENDOR_PRODUCTS:
      vendorCopy = _objectSpread({}, state.get('vendor'));
      vendorCopy.products = state.get('vendorProducts');
      return state.set('vendor', vendorCopy);
    case _vendors.GET_VENDOR_PRODUCTS_REQUEST:
      return state.set('errors', []).set('isProductsLoading', true);
    case _vendors.GET_VENDOR_PRODUCTS_SUCCESS:
      return state.set('vendorProducts', action.response).set('isProductsLoading', false);
    case _vendors.GET_VENDOR_PRODUCTS_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.GET_ACQUIRE_VENDORS_REQUEST:
      return state.set('acquireVendors', []).set('errors', []).set('isVendorsDialogLoading', true);
    case _vendors.GET_ACQUIRE_VENDORS_SUCCESS:
      return state.set('acquireVendors', action.response).set('isVendorsDialogLoading', false);
    case _vendors.GET_ACQUIRE_VENDORS_FAILURE:
      return state.set('isVendorsDialogLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.SELECT_VENDOR_REQUEST:
      return state.set('vendor', {}).set('errors', []).set('isLoading', true);
    case _vendors.SELECT_VENDOR_SUCCESS:
      return state.set('vendor', action.response).set('isLoading', false);
    case _vendors.SELECT_VENDOR_FAILURE:
      return state.set('isLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.GET_VENDOR_CONTACTS_REQUEST:
      return state.set('contacts', {}).set('errors', []).set('isContactsLoading', true);
    case _vendors.GET_VENDOR_CONTACTS_SUCCESS:
      return state.set('contacts', action.response).set('isContactsLoading', false);
    case _vendors.GET_VENDOR_CONTACTS_FAILURE:
      return state.set('isContactsLoading', false).set('error', action.messages).set('isApiError', true);
    case _vendors.CREATE_VENDOR_REQUEST:
      return state.set('isLoading', true);
    case _vendors.CREATE_VENDOR_SUCCESS:
      var newVendor = action.response;
      vendors = [].concat(state.get('vendors'));
      vendors.push(newVendor);
      return state.set('vendors', vendors).set('isLoading', false);
    case _vendors.CREATE_VENDOR_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _vendors.SAVE_VENDOR_REQUEST:
      return state.set('isLoading', true);
    case _vendors.SAVE_VENDOR_SUCCESS:
      return state.set('isLoading', false).set('vendor', {});
    case _vendors.SAVE_VENDOR_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _vendors.UPDATE_ACTIVATION_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _vendors.UPDATE_ACTIVATION_SUCCESS:
      vendorCopy = _objectSpread({}, state.get('vendor'));
      if (vendorCopy.status === 'Active' || vendorCopy.status === 'ACTIVE') {
        vendorCopy.status = 'DELETE';
      } else {
        vendorCopy.status = 'ACTIVE';
      }
      return state.set('vendor', vendorCopy).set('isLoading', false);
    case _vendors.UPDATE_ACTIVATION_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _vendors.ACQUIRE_PRODUCT_REQUEST:
      return state.set('errors', []).set('isLoading', true);
    case _vendors.ACQUIRE_PRODUCT_SUCCESS:
      vendorCopy = _objectSpread({}, state.get('vendor'));
      vendorCopy.products = action.response.products;
      return state.set('isLoading', false).set('vendor', vendorCopy).set('vendorProducts', [].concat(action.response.products));
    case _vendors.ACQUIRE_PRODUCT_FAILURE:
      return state.set('isLoading', false).set('error', action.messages);
    case _products.CREATE_PRODUCT_SUCCESS:
      if (action.payload.vendorID === state.get('vendor').vendorID) {
        vendorCopy = _objectSpread({}, state.get('vendor'));
        vendorCopy.products.push(_objectSpread({}, action.payload));
        return state.set('vendor', vendorCopy).set('vendorProducts', [].concat(action.payload));
      }
      return state;
    default:
      return state;
  }
};