"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMaintenance = exports.getMaintenance = exports.UPDATE_MAINTENANCE_SUCCESS = exports.UPDATE_MAINTENANCE_REQUEST = exports.UPDATE_MAINTENANCE_FAILURE = exports.GET_MAINTENANCE_SUCCESS = exports.GET_MAINTENANCE_REQUEST = exports.GET_MAINTENANCE_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_MAINTENANCE_REQUEST = exports.GET_MAINTENANCE_REQUEST = 'GET_MAINTENANCE_REQUEST';
var GET_MAINTENANCE_SUCCESS = exports.GET_MAINTENANCE_SUCCESS = 'GET_MAINTENANCE_SUCCESS';
var GET_MAINTENANCE_FAILURE = exports.GET_MAINTENANCE_FAILURE = 'GET_MAINTENANCE_FAILURE';
var UPDATE_MAINTENANCE_REQUEST = exports.UPDATE_MAINTENANCE_REQUEST = 'UPDATE_MAINTENANCE_REQUEST';
var UPDATE_MAINTENANCE_SUCCESS = exports.UPDATE_MAINTENANCE_SUCCESS = 'UPDATE_MAINTENANCE_SUCCESS';
var UPDATE_MAINTENANCE_FAILURE = exports.UPDATE_MAINTENANCE_FAILURE = 'UPDATE_MAINTENANCE_FAILURE';
var getMaintenance = exports.getMaintenance = function getMaintenance() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_MAINTENANCE_REQUEST, GET_MAINTENANCE_SUCCESS, GET_MAINTENANCE_FAILURE],
    authenticated: true,
    endpoint: "maintenance",
    method: 'GET'
  });
};
var updateMaintenance = exports.updateMaintenance = function updateMaintenance(id, data) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [UPDATE_MAINTENANCE_REQUEST, UPDATE_MAINTENANCE_SUCCESS, UPDATE_MAINTENANCE_FAILURE],
    authenticated: true,
    endpoint: "maintenance/".concat(id),
    method: 'PUT',
    payload: data
  });
};