"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEntityUntetheredAmbulatories = exports.getEntityTypes = exports.getEntitySubAcutes = exports.getEntityInHospitalDataCenters = exports.getEntityHospitals = exports.getEntityHomeHealths = exports.getEntityFreeStandingDataCenters = exports.getEntityAmbulatories = exports.GET_ENTITY_UNTETHERED_AMBULATORIES_SUCCESS = exports.GET_ENTITY_UNTETHERED_AMBULATORIES_REQUEST = exports.GET_ENTITY_UNTETHERED_AMBULATORIES_FAILURE = exports.GET_ENTITY_TYPES_SUCCESS = exports.GET_ENTITY_TYPES_REQUEST = exports.GET_ENTITY_TYPES_FAILURE = exports.GET_ENTITY_SUBACUTES_SUCCESS = exports.GET_ENTITY_SUBACUTES_REQUEST = exports.GET_ENTITY_SUBACUTES_FAILURE = exports.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_SUCCESS = exports.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_REQUEST = exports.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_FAILURE = exports.GET_ENTITY_HOSPITALS_SUCCESS = exports.GET_ENTITY_HOSPITALS_REQUEST = exports.GET_ENTITY_HOSPITALS_FAILURE = exports.GET_ENTITY_HOME_HEALTHS_SUCCESS = exports.GET_ENTITY_HOME_HEALTHS_REQUEST = exports.GET_ENTITY_HOME_HEALTHS_FAILURE = exports.GET_ENTITY_FREE_STANDING_DATA_CENTERS_SUCCESS = exports.GET_ENTITY_FREE_STANDING_DATA_CENTERS_REQUEST = exports.GET_ENTITY_FREE_STANDING_DATA_CENTERS_FAILURE = exports.GET_ENTITY_AMBULATORIES_SUCCESS = exports.GET_ENTITY_AMBULATORIES_REQUEST = exports.GET_ENTITY_AMBULATORIES_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_ENTITY_TYPES_REQUEST = exports.GET_ENTITY_TYPES_REQUEST = 'GET_ENTITY_TYPES_REQUEST';
var GET_ENTITY_TYPES_SUCCESS = exports.GET_ENTITY_TYPES_SUCCESS = 'GET_ENTITY_TYPES_SUCCESS';
var GET_ENTITY_TYPES_FAILURE = exports.GET_ENTITY_TYPES_FAILURE = 'GET_ENTITY_TYPES_FAILURE';
var GET_ENTITY_HOSPITALS_REQUEST = exports.GET_ENTITY_HOSPITALS_REQUEST = 'GET_ENTITY_HOSPITALS_REQUEST';
var GET_ENTITY_HOSPITALS_SUCCESS = exports.GET_ENTITY_HOSPITALS_SUCCESS = 'GET_ENTITY_HOSPITALS_SUCCESS';
var GET_ENTITY_HOSPITALS_FAILURE = exports.GET_ENTITY_HOSPITALS_FAILURE = 'GET_ENTITY_HOSPITALS_FAILURE';
var GET_ENTITY_AMBULATORIES_REQUEST = exports.GET_ENTITY_AMBULATORIES_REQUEST = 'GET_ENTITY_AMBULATORIES_REQUEST';
var GET_ENTITY_AMBULATORIES_SUCCESS = exports.GET_ENTITY_AMBULATORIES_SUCCESS = 'GET_ENTITY_AMBULATORIES_SUCCESS';
var GET_ENTITY_AMBULATORIES_FAILURE = exports.GET_ENTITY_AMBULATORIES_FAILURE = 'GET_ENTITY_AMBULATORIES_FAILURE';
var GET_ENTITY_SUBACUTES_REQUEST = exports.GET_ENTITY_SUBACUTES_REQUEST = 'GET_ENTITY_SUBACUTES_REQUEST';
var GET_ENTITY_SUBACUTES_SUCCESS = exports.GET_ENTITY_SUBACUTES_SUCCESS = 'GET_ENTITY_SUBACUTES_SUCCESS';
var GET_ENTITY_SUBACUTES_FAILURE = exports.GET_ENTITY_SUBACUTES_FAILURE = 'GET_ENTITY_SUBACUTES_FAILURE';
var GET_ENTITY_HOME_HEALTHS_REQUEST = exports.GET_ENTITY_HOME_HEALTHS_REQUEST = 'GET_ENTITY_HOME_HEALTHS_REQUEST';
var GET_ENTITY_HOME_HEALTHS_SUCCESS = exports.GET_ENTITY_HOME_HEALTHS_SUCCESS = 'GET_ENTITY_HOME_HEALTHS_SUCCESS';
var GET_ENTITY_HOME_HEALTHS_FAILURE = exports.GET_ENTITY_HOME_HEALTHS_FAILURE = 'GET_ENTITY_HOME_HEALTHS_FAILURE';
var GET_ENTITY_FREE_STANDING_DATA_CENTERS_REQUEST = exports.GET_ENTITY_FREE_STANDING_DATA_CENTERS_REQUEST = 'GET_ENTITY_FREE_STANDING_DATA_CENTERS_REQUEST';
var GET_ENTITY_FREE_STANDING_DATA_CENTERS_SUCCESS = exports.GET_ENTITY_FREE_STANDING_DATA_CENTERS_SUCCESS = 'GET_ENTITY_FREE_STANDING_DATA_CENTERS_SUCCESS';
var GET_ENTITY_FREE_STANDING_DATA_CENTERS_FAILURE = exports.GET_ENTITY_FREE_STANDING_DATA_CENTERS_FAILURE = 'GET_ENTITY_FREE_STANDING_DATA_CENTERS_FAILURE';
var GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_REQUEST = exports.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_REQUEST = 'GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_REQUEST';
var GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_SUCCESS = exports.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_SUCCESS = 'GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_SUCCESS';
var GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_FAILURE = exports.GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_FAILURE = 'GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_FAILURE';
var GET_ENTITY_UNTETHERED_AMBULATORIES_REQUEST = exports.GET_ENTITY_UNTETHERED_AMBULATORIES_REQUEST = 'GET_ENTITY_UNTETHERED_AMBULATORIES_REQUEST';
var GET_ENTITY_UNTETHERED_AMBULATORIES_SUCCESS = exports.GET_ENTITY_UNTETHERED_AMBULATORIES_SUCCESS = 'GET_ENTITY_UNTETHERED_AMBULATORIES_SUCCESS';
var GET_ENTITY_UNTETHERED_AMBULATORIES_FAILURE = exports.GET_ENTITY_UNTETHERED_AMBULATORIES_FAILURE = 'GET_ENTITY_UNTETHERED_AMBULATORIES_FAILURE';
var getEntityTypes = exports.getEntityTypes = function getEntityTypes() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_TYPES_REQUEST, GET_ENTITY_TYPES_SUCCESS, GET_ENTITY_TYPES_FAILURE],
    authenticated: true,
    endpoint: "entityTypes",
    method: 'GET'
  });
};
var getEntityHospitals = exports.getEntityHospitals = function getEntityHospitals(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_HOSPITALS_REQUEST, GET_ENTITY_HOSPITALS_SUCCESS, GET_ENTITY_HOSPITALS_FAILURE],
    authenticated: true,
    endpoint: "entity/hospitals?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};
var getEntityAmbulatories = exports.getEntityAmbulatories = function getEntityAmbulatories(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_AMBULATORIES_REQUEST, GET_ENTITY_AMBULATORIES_SUCCESS, GET_ENTITY_AMBULATORIES_FAILURE],
    authenticated: true,
    endpoint: "entity/ambulatories?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};
var getEntitySubAcutes = exports.getEntitySubAcutes = function getEntitySubAcutes(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_SUBACUTES_REQUEST, GET_ENTITY_SUBACUTES_SUCCESS, GET_ENTITY_SUBACUTES_FAILURE],
    authenticated: true,
    endpoint: "entity/subacutes?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};
var getEntityHomeHealths = exports.getEntityHomeHealths = function getEntityHomeHealths(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_HOME_HEALTHS_REQUEST, GET_ENTITY_HOME_HEALTHS_SUCCESS, GET_ENTITY_HOME_HEALTHS_FAILURE],
    authenticated: true,
    endpoint: "entity/homehealths?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};
var getEntityFreeStandingDataCenters = exports.getEntityFreeStandingDataCenters = function getEntityFreeStandingDataCenters(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_FREE_STANDING_DATA_CENTERS_REQUEST, GET_ENTITY_FREE_STANDING_DATA_CENTERS_SUCCESS, GET_ENTITY_FREE_STANDING_DATA_CENTERS_FAILURE],
    authenticated: true,
    endpoint: "entity/freestandingdatacenters?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};
var getEntityInHospitalDataCenters = exports.getEntityInHospitalDataCenters = function getEntityInHospitalDataCenters(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_REQUEST, GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_SUCCESS, GET_ENTITY_IN_HOSPITAL_DATA_CENTERS_FAILURE],
    authenticated: true,
    endpoint: "entity/inhospitaldatacenters?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};
var getEntityUntetheredAmbulatories = exports.getEntityUntetheredAmbulatories = function getEntityUntetheredAmbulatories(entityID) {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_ENTITY_UNTETHERED_AMBULATORIES_REQUEST, GET_ENTITY_UNTETHERED_AMBULATORIES_SUCCESS, GET_ENTITY_UNTETHERED_AMBULATORIES_FAILURE],
    authenticated: true,
    endpoint: "entity/untetheredAmbulatories?parentUniqueID=".concat(entityID),
    method: 'GET'
  });
};