"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResources = exports.GET_RESOURCES_SUCCESS = exports.GET_RESOURCES_REQUEST = exports.GET_RESOURCES_FAILURE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("../middleware/api");
var GET_RESOURCES_REQUEST = exports.GET_RESOURCES_REQUEST = 'GET_RESOURCES_REQUEST';
var GET_RESOURCES_SUCCESS = exports.GET_RESOURCES_SUCCESS = 'GET_RESOURCES_SUCCESS';
var GET_RESOURCES_FAILURE = exports.GET_RESOURCES_FAILURE = 'GET_RESOURCES_FAILURE';
var getResources = exports.getResources = function getResources() {
  return (0, _defineProperty2["default"])({}, _api.CALL_API, {
    types: [GET_RESOURCES_REQUEST, GET_RESOURCES_SUCCESS, GET_RESOURCES_FAILURE],
    authenticated: false,
    endpoint: "resources",
    method: 'GET'
  });
};