export const AccessoryColors = {
  highlight: '#6EAC4A',
  grayDark: '#777',
  error: '#E86C60'
};

export const HIMSSDarkBlue = {
  50: '#e0e9ed',
  100: '#b3c8d1',
  200: '#80a3b3',
  300: '#4d7e94',
  400: '#26627d',
  500: '#1a25ab',
  600: '#003f5e',
  700: '#000',
  800: '#002f49',
  900: '#002038',
  A100: '#6eb3ff',
  A200: '#3b98ff',
  A400: '#087eff',
  A700: '#0071ee',
  contrastDefaultColor: 'light'
};

export const HIMSSBlue = {
  50: '#e0edf2',
  100: '#b3d2de',
  200: '#80b4c9',
  300: '#4d95b3',
  400: '#267fa2',
  500: '#006892',
  600: '#00608a',
  700: '#00557f',
  800: '#004b75',
  900: '#003a63',
  A100: '#93caff',
  A200: '#60b0ff',
  A400: '#4dc0e8',
  A700: '#148bff',
  contrastDefaultColor: 'light'
};

export const SilverSand = {
  50: '#f7f8f8',
  100: '#eceded',
  200: '#e0e1e2',
  300: '#d3d4d6',
  400: '#c9cbcd',
  500: '#c0c2c4',
  600: '#babcbe',
  700: '#b2b4b6',
  800: '#aaacaf',
  900: '#9c9fa2',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#e0f0ff',
  A700: '#c7e3ff',
  contrastDefaultColor: 'dark'
};

//export const himssYellow
