import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AccessoryColors } from '../layout/styles/colors';
import { hideToast } from './layout.actions';

class Toast extends Component {
  render() {
    let toastWrapperClassName = 'toast-wrapper';
    if (this.props.isShowToast) {
      toastWrapperClassName += ' active';
    }

    if (this.props.responsiveBreakpoint !== 'SMALL') {
      toastWrapperClassName += ' toast-wrapper-large';
    }

    return (
      <div
        className={toastWrapperClassName}
        style={
          {
            // bottom: this.props.toastPosition,
          }
        }
      >
        <div
          className="toast-message"
          style={{
            backgroundColor: this.props.isToastFailure ? AccessoryColors.error : AccessoryColors.highlight
          }}
        >
          <div className="toast-message-text-wrapper">
            <div className="toast-message-text">{this.props.toastMessage}</div>
            <div className="close-button" onClick={() => this.props.hideToast()}>
              <i className="material-icons">close</i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isShowToast: state.layout.get('isShowToast'),
    toastMessage: state.layout.get('toastMessage'),
    isToastFailure: state.layout.get('isToastFailure'),
    toastPosition: state.layout.get('toastPosition'),
    responsiveBreakpoint: state.layout.get('responsiveBreakpoint')
  };
};

export default connect(
  mapStateToProps,
  {
    hideToast
  }
)(Toast);
