"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getResource = void 0;
var getResource = exports.getResource = function getResource(resources, language, type, key) {
  if (language && language.toLowerCase() === 'english') {
    language = null;
  }
  var txt = key;
  if (key === '' && resources && resources.length > 0 && Object.keys(resources[0].values).length > 0) {
    var values;
    if (language) {
      values = resources[0].values[language];
    } else {
      var firstKey = Object.keys(resources[0].values)[0];
      values = resources[0].values[firstKey];
    }
    if (values && values[type]) {
      var resource = values[type][0];
      if (resource) {
        if (language && language !== '') {
          txt = resource.translation;
        } else {
          txt = resource.defaultValue;
        }
        return txt;
      }
    }
  } else if (language && resources && resources.length > 0 && resources[0].values[language]) {
    var _values = resources[0].values[language];
    if (_values[type]) {
      var _resource = _values[type].find(function (r) {
        return r.defaultValue.toLowerCase() === key.toLowerCase();
      });
      if (_resource) {
        txt = _resource.translation;
      }
    }
  }
  return txt;
};